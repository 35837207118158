

import React from 'react';

const ShiftUsersIcon = (props) => {
  return (
    <React.Fragment>
      <div style={{ marginRight: '6%' }}>
        <svg width="18" height="20" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M16.875 16.875H15.75V14.0625C15.7491 13.3169 15.4525 12.602 14.9253 12.0747C14.398 11.5475 13.6831 11.2509 12.9375 11.25V10.125C13.9814 10.1262 14.9823 10.5414 15.7204 11.2796C16.4586 12.0177 16.8738 13.0186 16.875 14.0625V16.875Z" fill="white" fill-opacity="0.6" />
          <path d="M12.375 16.875H11.25V14.0625C11.2491 13.3169 10.9525 12.602 10.4253 12.0747C9.898 11.5475 9.18315 11.2509 8.4375 11.25H5.0625C4.31685 11.2509 3.602 11.5475 3.07475 12.0747C2.5475 12.602 2.25089 13.3169 2.25 14.0625V16.875H1.125V14.0625C1.12619 13.0186 1.54142 12.0177 2.27958 11.2796C3.01775 10.5414 4.01858 10.1262 5.0625 10.125H8.4375C9.48142 10.1262 10.4823 10.5414 11.2204 11.2796C11.9586 12.0177 12.3738 13.0186 12.375 14.0625V16.875Z" fill="white" fill-opacity="0.6" />
          <path d="M11.25 1.125V2.25C11.9959 2.25 12.7113 2.54632 13.2387 3.07376C13.7662 3.60121 14.0625 4.31658 14.0625 5.0625C14.0625 5.80842 13.7662 6.52379 13.2387 7.05124C12.7113 7.57868 11.9959 7.875 11.25 7.875V9C12.2943 9 13.2958 8.58516 14.0342 7.84673C14.7727 7.10831 15.1875 6.10679 15.1875 5.0625C15.1875 4.01821 14.7727 3.01669 14.0342 2.27827C13.2958 1.53984 12.2943 1.125 11.25 1.125Z" fill="white" fill-opacity="0.6" />
          <path d="M6.75 2.25C7.30626 2.25 7.85003 2.41495 8.31254 2.72399C8.77506 3.03303 9.13554 3.47229 9.34841 3.9862C9.56128 4.50012 9.61698 5.06562 9.50846 5.61119C9.39994 6.15676 9.13207 6.6579 8.73874 7.05124C8.3454 7.44457 7.84426 7.71244 7.29869 7.82096C6.75312 7.92948 6.18762 7.87378 5.6737 7.66091C5.15979 7.44804 4.72054 7.08755 4.41149 6.62504C4.10245 6.16253 3.9375 5.61876 3.9375 5.0625C3.9375 4.31658 4.23382 3.60121 4.76126 3.07376C5.28871 2.54632 6.00408 2.25 6.75 2.25ZM6.75 1.125C5.97124 1.125 5.20996 1.35593 4.56244 1.78859C3.91493 2.22125 3.41025 2.8362 3.11223 3.55568C2.81421 4.27517 2.73623 5.06687 2.88816 5.83067C3.04009 6.59447 3.4151 7.29606 3.96577 7.84673C4.51644 8.3974 5.21803 8.77241 5.98183 8.92434C6.74563 9.07627 7.53733 8.9983 8.25682 8.70028C8.9763 8.40226 9.59126 7.89758 10.0239 7.25006C10.4566 6.60254 10.6875 5.84126 10.6875 5.0625C10.6875 4.01821 10.2727 3.01669 9.53423 2.27827C8.79581 1.53984 7.79429 1.125 6.75 1.125Z" fill="white" fill-opacity="0.6" />
        </svg>
      </div>
    </React.Fragment>
  );
};

export default ShiftUsersIcon;