import PrimaryButton from '../primary-button';
import SecondaryButton from '../secondary-button';
import SvgAkarIconsTriangleAlert from '../../svg-icons/AkarIconsTriangleAlert';
import React from 'react';
import CommonModal from '../modal/modal';
import './AlertPopUp.css';


const deleteAlertHeader = () => (
  <div>
    <SvgAkarIconsTriangleAlert width = {30} height = {30} />
  </div>
);

const deleteAlertBody = ({ 
  // tableSelectionData,
  languageFile,
  checkOutSpace }) => (
  <div className="popup-body modal-custom-body modal-body-custom-padding">
    {`${languageFile["web.checkout"] ? languageFile["web.checkout"].replace("${amenity}", `${(checkOutSpace && checkOutSpace.amenityType && checkOutSpace.amenityType.name && checkOutSpace.amenityType.name === "Car_park") ? languageFile["carpark.parking"] : "Desk"} - ${checkOutSpace && checkOutSpace.amenity && checkOutSpace.amenity.name ? checkOutSpace.amenity.name : ""}`) : "Check Out"}`}?
    {/* {tableSelectionData}? */}
  </div>
);

const deleteAlertFooter = (handleClose,handleComplete) => (
  <div style={{ textAlign: 'center' }} className="col-sm-12">
    <PrimaryButton id='cb'
      buttonType="small"
      fontSize='var(--size-12)'
      style={{ margin: '0px 10px', letterSpacing: '0.2px' }}
      text={"Yes!"}
      action={handleComplete}
    />
    <SecondaryButton
      buttonType="small"
      fontSize='var(--size-12)'
      style={{ letterSpacing: '0.2px' }}
      text={"Cancel"}
      action={() => handleClose()}
    />
  </div>
);


function AlertPopUp(props) {
  const getFunctions = (type) => {
    switch (type) {
      case 'checkout':
        return {
          deleteAlertHeader,
          deleteAlertBody,
          deleteAlertFooter,
        };
    }
  };

  return (
    <React.Fragment>
      <CommonModal
        headerChildren={getFunctions(props.modalType).deleteAlertHeader()}
        bodychildren={getFunctions(props.modalType).deleteAlertBody({
          // tableSelectionData:props.modalType,
          languageFile:props.languageFile,
          checkOutSpace:props.checkOutSpace
        })}
        footerChildrens={getFunctions(props.modalType).deleteAlertFooter(props.handleClose,props.disabled? null : props.handleComplete )}
        handleCloseModal={props.handleClose}
        show={true}
        fullscreen={false}
        centerAlignHeader={true}
        modalType={props.modalType}
      />
    </React.Fragment>
  );
}
export default AlertPopUp;
