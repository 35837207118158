import * as React from "react";

const SvgCleanerusers = (props) => (
  <svg
    width={18}
    height={18}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      className="fill"
      d="M11.568 8.59h-.659V3.319C10.91 2.593 10.316 2 9.591 2H8.273c-.725 0-1.318.593-1.318 1.318v5.273h-.66A3.297 3.297 0 0 0 3 11.886V16.5h11.864v-4.614a3.297 3.297 0 0 0-3.296-3.295ZM8.273 3.319H9.59v5.273H8.273V3.318Zm5.273 11.864h-1.319v-1.978a.661.661 0 0 0-.659-.659.661.661 0 0 0-.659.66v1.977H9.591v-1.978a.66.66 0 0 0-.66-.659.66.66 0 0 0-.658.66v1.977H6.955v-1.978a.661.661 0 0 0-.66-.659.66.66 0 0 0-.659.66v1.977H4.318v-3.296c0-1.087.89-1.977 1.977-1.977h5.273c1.088 0 1.978.89 1.978 1.977v3.296Z"
      fill="#fff"
      fillOpacity={0.6}
    />
  </svg>
);

export default SvgCleanerusers;
