import React from "react";
import styles from "../../styles/react-select-styles";
import Select from 'react-select';
import './index.css';


const Dropdown = (props) => (

  <Select
    styles={styles}
    // menuIsOpen={true}
    onChange={props.onChange}
    value={props.value}
    closeMenuOnSelect={props.closeMenuOnSelect}
    isSearchable={props.isSearchable}
    className='react-select-container'
    classNamePrefix="react-select"
    placeholder={props.placeholder || "Select"}
    isClearable={props.isClearable || false} />
);

export default Dropdown;
