import * as React from "react";

const SvgStethoscope = (props) => (
  <svg
    width={18}
    height={18}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      className="fill"
      d="M8.5 2.018a7 7 0 0 0-4.79 11.566L8.5 8.793V2.018Zm1 0V8.5h6.482A7.001 7.001 0 0 0 9.5 2.018ZM15.982 9.5H9.207l-4.79 4.79A7 7 0 0 0 15.982 9.5ZM1 9a8 8 0 1 1 16 0A8 8 0 0 1 1 9Z"
      fill="#fff"
      fillOpacity={0.6}
    />
  </svg>
);

export default SvgStethoscope;
