import React from 'react';
import FeatureService from './../../js/services/feature-service';
import Loader from './../ui/loader';
import _ from 'lodash';

const defaultErrorMessage = (<React.Fragment>
  You do not have the necessary role or permissions to view this.<br />
  Please contact the administrator for more information.<br />
  {/* Missing permission: <strong><em>{this.props.token}</em></strong> */}
</React.Fragment>);

const defaultLoadingMessage = <Loader />;

class Feature extends React.Component {
  state = {
    loading: true
  }
  componentDidMount() {
    FeatureService.waitForInit().then(() => {
      if (!this.unmounted) {
        this.setState({ loading: false });
      }
    });
  }

  componentWillUnmount() {
    this.unmounted = true;
  }

  wrapMessage(message) {
    return (<div style={{ padding: 20, textAlign: 'center' }}>
      {message}
    </div>);
  }

  renderMessage(message, wrap = false) {
    if (this.props.showMessage !== false && message) {
      if (wrap) {
        return this.wrapMessage(message);
      }
      return message;
    }
    return '';
  }

  render() {
    if (this.state.loading) {
      return this.renderMessage(defaultLoadingMessage, true);
    }
    const tokens = Array.isArray(this.props.token) ? this.props.token : [this.props.token];
    if (_.some(tokens, token => FeatureService.isEnabled(token))) {
      return this.props.children;
    }
    return this.renderMessage(defaultErrorMessage, true);

  }
}
export default Feature;
