import * as React from "react";

const SvgFire = (props) => (
  <svg
    width={18}
    height={18}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      className="fill"
      d="M11.25 10.125h1.125v1.125H11.25v-1.125ZM15.75 9h1.125v1.125H15.75V9ZM7.875 3.375H9V4.5H7.875V3.375ZM9 12.375H6.75V9a1.126 1.126 0 0 0-1.125-1.125H2.25A1.126 1.126 0 0 0 1.125 9v3.375A1.126 1.126 0 0 0 2.25 13.5h3.375v2.25a1.126 1.126 0 0 0 1.125 1.125H9a1.126 1.126 0 0 0 1.125-1.125V13.5A1.126 1.126 0 0 0 9 12.375Zm-6.75 0V9h3.375v3.375H2.25Zm4.5 3.375V13.5H9v2.25H6.75ZM15.75 16.875H13.5a1.126 1.126 0 0 1-1.125-1.125V13.5a1.126 1.126 0 0 1 1.125-1.125h2.25a1.126 1.126 0 0 1 1.125 1.125v2.25a1.126 1.126 0 0 1-1.125 1.125ZM13.5 13.5v2.25h2.25V13.5H13.5ZM15.75 1.125h-3.375A1.126 1.126 0 0 0 11.25 2.25v3.375h-1.125A1.126 1.126 0 0 0 9 6.75v1.125A1.126 1.126 0 0 0 10.125 9h1.125a1.126 1.126 0 0 0 1.125-1.125V6.75h3.375a1.126 1.126 0 0 0 1.125-1.125V2.25a1.126 1.126 0 0 0-1.125-1.125Zm-5.625 6.75V6.75h1.125v1.125h-1.125Zm2.25-2.25V2.25h3.375v3.375h-3.375ZM4.5 5.625H2.25A1.126 1.126 0 0 1 1.125 4.5V2.25A1.126 1.126 0 0 1 2.25 1.125H4.5A1.126 1.126 0 0 1 5.625 2.25V4.5A1.126 1.126 0 0 1 4.5 5.625ZM2.25 2.25V4.5H4.5V2.25H2.25Z"
      fill={props.iconColor}
      fillOpacity={0.6}
    />
  </svg>
);

export default SvgFire;
