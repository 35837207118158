export default {
  control: (styles, { hasValue, isDisabled, isFocused }) => ({
    ...styles,
    margin: "4px 0 0",
    padding: "0px",
    borderRadius: "8px",
    border: "solid 1px solid rgb(199, 199, 199)  !important",
    backgroundColor: hasValue
      ? "background-color: rgb(255, 255, 255)"
      : "#f7f7f7",
    borderColor: isFocused ? isDisabled ? "hsl(0,0%,90%);" : "var(--primary-green) !important" : "rgba(68, 68, 68,0.3)",
    boxShadow: "none",
    fontFamily: "var(--font-family-nunito)",
    fontSize: "var(--size-14)"

  }),
  menu: (styles) => ({
    ...styles,
    fontFamily: "var(--font-family-nunito)",
    fontSize: "var(--size-14)",
    zIndex: 999,
    backgroundColor: "#ffffff",
    height: '42px',
    padding: "8px 0",
    border: "0px",
    "margin-top": "1px"
  }),
  singleValue: (styles) => ({
    ...styles,
    fontFamily: "var(--font-family-nunito)",
    fontSize: "var(--size-14)",
    color: "black", // "var(--black)",
  }),
  multiValue: (styles) => ({
    ...styles,
    fontFamily: "var(--font-family-nunito)",
    fontSize: "var(--size-14)",
    height: "32px",
    margin: "1px 4px 1px 0",
    padding: "5px 12px",
    backgroundColor: "rgba(29, 34, 82, 1)",
    fontWeight: '400',
    lineHeight: '18px',
    letterSpacing: '-0.01em',
    fontStretch: "normal",
    fontStyle: "normal",
    textAlign: "left",
    color: "#444444",
    cursor: "pointer",
  }),
  multiValueLabel: (styles) => ({
    ...styles,
    fontFamily: "var(--font-family-nunito)",
    fontSize: "var(--size-14)",
    height: "32px",
    margin: "1px 4px 1px 0",
    fontSize: "13px",
    fontWeight: '400',
    lineHeight: '18px',
    letterSpacing: '-0.01em',
    color: '#444444',
    fontStretch: "normal",
    fontStyle: "normal",
    padding: "0",
    "padding-left": "0"
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: data.color,
    ":hover": {
      color: "#444444",
    },
  }),
  menuList: (styles) => {
    return {
      ...styles,
      fontFamily: "var(--font-family-nunito)",
      fontSize: "var(--size-14)",
    };
  },
  option: (styles) => {
    return {
      ...styles,
      // margin: "4px 0 4px 0px",
      fontFamily: "var(--font-family-nunito)",
      fontSize: "var(--size-14)",
      opacity: 1,
      backgroundColor: "#fff",
      fontWeight: '400',
      lineHeight: '18px',
      letterSpacing: '-0.01em',
      zIndex: 20,
      fontStretch: "normal",
      fontStyle: "normal",
      textAlign: "left",
      color: "#444444",
      cursor: "pointer",
      ":hover": {
        backgroundColor: "var(--hover-green)",
        opacity: 1,
      },
    };
  },
  placeholder: (defaultStyles) => {
    return {
      ...defaultStyles,
      fontFamily: "var(--font-family-nunito)",
      fontSize: "var(--size-14)",
      opacity: '1',
    };
  },
};
