import _ from 'lodash';
import SpaceIO from './SpaceIO';
import FeatureService from './feature-service';
import BootstrapService from './bootstrap-service';
import BaseService from './base-service';
import FreespaceappService from '../../js/services/Freespaceapp-service';
import CacheService from '../../js/services/cache-service';

const getLanguageFile = async () => {
  const userInfo = await FreespaceappService.MyDetail();
  const language = userInfo.data.me.langPreferences || 'en';
  const languageFile = await SpaceIO.api.authGetFreespaceappNoGraphQL(`translation?language=${language}`);
  const savableFile = languageFile ? JSON.stringify(languageFile) : null;
  CacheService.set('language-file', savableFile);
  return languageFile;
};

const loadLanguage = async () => {
  const lngFile = CacheService.get('language-file');
  const languageFile = lngFile ? JSON.parse(lngFile) : await getLanguageFile();
  return languageFile;
};

const featureEnabledForLocationOrChildren = (location, feature) => {
  return FeatureService.isEnabled(feature, location.id) || _.any(location.locations, child => featureEnabledForLocationOrChildren(child, feature));
};
const locationMenu = (locResponse, pageUrl, feature) => {
  let menu = {};
  const locType = _.get(locResponse, 'type', "FLOOR");
  menu.name = _.get(locResponse, 'name', "Location");
  menu.url = _.get(locResponse, 'plottable', false) ? "./" + pageUrl + "#" + locResponse.id : "#";
  menu.icon = _.get(locIcons, locType, "circle-o");
  if ('locations' in locResponse) {
    menu.items = locResponse.locations
      .filter(l => l.showInMenu && (_.get(l, 'type', "FLOOR") === 'FLOOR' || featureEnabledForLocationOrChildren(l, feature)))
      .map(location => locationMenu(location, pageUrl, feature));
  }
  return menu;
};
const screensMenu = (locResponse, screens, featureToken) => {
  let menu = {};
  menu.name = _.get(locResponse, 'name', "Location");
  let hasScreen = _.get(locResponse, 'plottable', false) && screens.hasOwnProperty(locResponse.id);
  menu.url = "#";
  menu.feature = featureToken;
  if (hasScreen) {
    updateMenuUrl(menu, locResponse, screens);
  }
  menu.hasScreen = hasScreen;
  menu.icon = _.get(locIcons, _.get(locResponse, 'type', "FLOOR"), "circle-o");
  if ('locations' in locResponse) {
    menu.items = locResponse.locations.filter(l => l.showInMenu).map(location => {
      let m = screensMenu(location, screens, featureToken);
      menu.hasScreen = menu.hasScreen || m.hasScreen;
      return m;
    }).filter(m => m.hasScreen);
    if (screens.hasOwnProperty(locResponse.id)) {
      let lobbyMenu = { name: "Lobby", icon: "circle-o", feature: featureToken };
      updateMenuUrl(lobbyMenu, locResponse, screens);
      menu.items.push(lobbyMenu);
    }
  }
  return menu;
};
const updateMenuUrl = (menu, locResponse, screens) => {
  let signageObj = screens[locResponse.id][0];
  if (signageObj.displayResolution) {
    let screenOrintation = signageObj.displayResolution.split("_")[1].toLowerCase();
    menu.url = "view-" + screenOrintation + ".html#" + signageObj.id;
  } else {
    menu.url = "signage-auth-v1.html#" + signageObj.id;
  }
  menu.target = "_blank";
};
const locIcons = {
  "REGION": "map-o",
  "FLOOR": "circle-o"
};
const menuMaster = [
  {
    name: "Dashboard",
    icon: "bar-chart",
    feature: 'ACCOUNT.DASHBOARD',
    status: "LIVE",
    items: [
      {
        name: "Portfolio Status",
        icon: "building",
        url: "home.html",
        feature: 'ACCOUNT.DASHBOARD',
        homeOrder: 0,
      }, {
        name: "Free Space Summary",
        icon: "building",
        url: "home.html",
        feature: 'ACCOUNT.SUMMARY',
        homeOrder: 6,
      },
      {
        name: "Live Floor Plan",
        icon: "map",
        url: "space-allocation-floor-plan.html",
        feature: 'ACCOUNT.LIVE_FLOOR_MAP'
      }, {
        name: "Todays Heat Map",
        icon: "fire",
        url: "heatmap-v2.html",
        feature: 'ACCOUNT.LIVE_HEAT_MAP',
        locationMenu: true
      }]
  }, {
    name: "Analytics",
    icon: "bar-chart",
    feature: 'ACCOUNT.ANALYTICS',
    items: [
      // {
      //   name: "Air Quality",
      //   icon: "tree",
      //   url: "#",
      //   feature: "ACCOUNT.ANALYTICS.AIR_QUALITY",
      //   items: [
      //     {
      //       name: "CO2 Dash",
      //       icon: "globe",
      //       url: "co2dash-3.html",
      //       feature: "ACCOUNT.ANALYTICS.AIR_QUALITY.CO2_DASHBOARD"
      //     },
      //     {
      //       name: "CO2 Weekly",
      //       icon: "stethoscope",
      //       url: "co2-analytics.html",
      //       feature: "ACCOUNT.ANALYTICS.AIR_QUALITY.CO2_ANALYTICS"
      //     },
      //     {
      //       name: "COu2082",
      //       icon: "globe",
      //       url: "co2dash.html",
      //       feature: "ACCOUNT.ANALYTICS.AIR_QUALITY.CO2_DASHBOARD_LEGACY"
      //     }
      //   ]
      // },
      {
        name: "Environment",
        icon: "tree",
        url: "#",
        feature: "ACCOUNT.ANALYTICS.ENVIRONMENT",
        items: [
          {
            name: "Comfort",
            icon: "globe",
            url: "comfort-voronoi-1.html",
            feature: "ACCOUNT.ANALYTICS.ENVIRONMENT.COMFORT",
          },
          {
            name: "Gradient Analysis",
            icon: "line-chart",
            url: "temp-humidity.html",
            feature: "ACCOUNT.ANALYTICS.ENVIRONMENT.TEMP_HUMIDITY",
          },
          {
            name: "Gradient Spatial Analysis",
            icon: "map",
            url: "temp-humidity-spatial.html",
            feature: "ACCOUNT.ANALYTICS.ENVIRONMENT.TEMP_HUMIDITY",
          }
        ]
      }, {
        name: "Demand",
        icon: "fa fa-hand-paper-o",
        url: "#",
        feature: "ACCOUNT.ANALYTICS.DEMAND",
        items: [
          {
            name: "Spatial Analysis",
            icon: "child",
            url: "demand-spatial-analysis.html",
            feature: "ACCOUNT.ANALYTICS.DEMAND.DEMAND_SPATIAL",
          },
          {
            name: "Timeline Analysis",
            icon: "child",
            url: "demand-timeline-analysis.html",
            feature: "ACCOUNT.ANALYTICS.DEMAND.DEMAND_TIMELINE",
          }
        ]
      }, {
        name: "Occupancy",
        icon: "users",
        url: "#",
        feature: "ACCOUNT.ANALYTICS.OCCUPANCY",
        items: [
          {
            name: "AM PM Analysis",
            icon: "child",
            url: "occupancy-during-day.html",
            feature: "ACCOUNT.ANALYTICS.OCCUPANCY.AM_PM",
          },
          {
            name: "Booking vs Attendees",
            icon: "child",
            url: "occupancy-vs-booking.html",
            feature: "ACCOUNT.ANALYTICS.OCCUPANCY.BOOKING_ATTENDEES_MS",
          },
          {
            name: "Booking vs Attendees",
            icon: "child",
            url: "occupancy-vs-n-booking.html",
            feature: "ACCOUNT.ANALYTICS.OCCUPANCY.BOOKING_ATTENDEES_NFS",
          },
          {
            name: "Meeting Room Analysis",
            icon: "child",
            url: "occupancy-room-analysis.html",
            feature: "ACCOUNT.ANALYTICS.OCCUPANCY.ROOM_ANALYSIS",
          },
          {
            name: "Meeting Analysis",
            icon: "child",
            url: "tim-analysis.html",
            feature: "ACCOUNT.ANALYTICS.OCCUPANCY.TIM.SUMMARY_PERFORMANCE",
            // items: [
            //   {
            //     name: "Summary Performance",
            //     icon: "child",
            //     url: "tim-analysis.html",
            //     feature: "ACCOUNT.ANALYTICS.OCCUPANCY.TIM.SUMMARY_PERFORMANCE",
            //   },
            // ],

          },
          {
            name: "Category Analysis",
            icon: "table",
            url: "category-analysis.html",
            feature: "ACCOUNT.ANALYTICS.OCCUPANCY.CATEGORY",
          },
          {
            name: "Child Sensor Live",
            icon: "line-chart",
            url: "child-live-view.html",
            feature: "ACCOUNT.ANALYTICS.OCCUPANCY.CHILD",
          },
          {
            name: "Department Analysis",
            icon: "table",
            url: "department-analysis.html",
            feature: "ACCOUNT.ANALYTICS.OCCUPANCY.DEPARTMENT",
          },
          {
            name: "Spatial Analysis",
            icon: "child",
            url: "occupancy-spatial-pc-180208.html",
            feature: "ACCOUNT.ANALYTICS.OCCUPANCY.SPATIAL_LEGACY",
          },
          {
            name: "Timeline Analysis",
            icon: "child",
            url: "occupancy-timeline-pc1803.html",
            feature: "ACCOUNT.ANALYTICS.OCCUPANCY.TIMELINE_LEGACY",
          },
          {
            name: "Spatial Analysis",
            icon: "child",
            url: "occupancy-spatial-analysis.html",
            feature: "ACCOUNT.ANALYTICS.OCCUPANCY.SPATIAL_V2",
          },
          {
            name: "Timeline Analysis",
            icon: "child",
            url: "occupancy-timeline-analysis.html",
            feature: "ACCOUNT.ANALYTICS.OCCUPANCY.TIMELINE_V2",
          },
          {
            name: "Weekly Analysis",
            icon: "calendar",
            url: "occupancy-weekly.html",
            feature: "ACCOUNT.ANALYTICS.OCCUPANCY.WEEKLY",
          },
          {
            name: "Weekly Analysis",
            icon: "calendar",
            url: "occupancy-weekly-v1.html",
            feature: "ACCOUNT.ANALYTICS.OCCUPANCY.WEEKLY_V1",
          },
          {
            name: "Fire Loading Analysis",
            icon: "users",
            url: "fire-loading.html",
            feature: "ACCOUNT.ANALYTICS.OCCUPANCY.FIRE_LOADING",
          },

        ]
      },
    ]
  }, {
    name: "Operations",
    icon: "tasks",
    url: "#",
    feature: "ACCOUNT.OPERATIONS",
    items: [
      {
        name: "Manage Cleaners",
        icon: "Cleanerusers",
        url: "manage-cleaner.html",
        feature: "PERMISSIONS.MANAGE_CLEANER",
      },
      {
        name: "Cleaning Schedule",
        icon: "clock-o",
        url: "reporting-cleaning.html",
        feature: "ACCOUNT.OPERATIONS.CLEANING.SCHEDULE",
      }, {
        name: "Cleaning Analysis",
        icon: "tablet",
        url: "cleaning-performance-metrics.html",
        feature: "ACCOUNT.OPERATIONS.CLEANING.PERFORMANCE_METRICS",
      }, {
        name: "Performance Dashboard",
        icon: "pie-chart",
        url: "cleaning-performance-dashboard.html",
        feature: "ACCOUNT.OPERATIONS.CLEANING.PERFORMANCE_DASHBOARD",
      },
    ],
  }, {
    name: "Reports",
    icon: "file",
    url: "report-ui.html",
    feature: 'ACCOUNT.REPORTS_V3',
    items: [{
      name: "Reports",
      icon: "file",
      url: "reporting.html",
      feature: 'ACCOUNT.REPORTS_V2'
    }, {
      name: "Reports",
      icon: "file",
      url: "reports.html",
      feature: 'ACCOUNT.REPORTS'
    }, {
      name: "Reports",
      icon: "file",
      url: "report-ui.html",
      feature: 'ACCOUNT.REPORTS_V3'
    }, {
      name: "Diagnostics",
      icon: "stethoscope",
      url: "diagnostics.html",
      feature: 'ACCOUNT.DIAGNOSTICS'
    }]
  }, {
    name: "Space Planner",
    icon: "sliders",
    url: "#",
    feature: 'ACCOUNT.SPACE_PLANNER',
    items: [
      {
        name: "Overview",
        icon: "dashboard",
        url: "space-planner-overview.html",
        feature: 'ACCOUNT.SPACE_PLANNER',
      },
      {
        name: 'Capacity',
        icon: 'calendar',
        url: "space-planner-plancapacity.html",
        feature: 'ACCOUNT.SPACE_PLANNER',
      },
      {
        name: "Demand",
        icon: "spusers",
        url: "space-planner-manage-demand.html",
        feature: 'ACCOUNT.SPACE_PLANNER',
      },
      {
        name: "Rule Set",
        icon: "cogs",
        url: "space-planner-rule-set.html",
        feature: 'ACCOUNT.SPACE_PLANNER',
      },
    ],
  },
  {
    name: "Shift Management",
    icon: "sliders",
    url: "#",
    feature: 'ACCOUNT.SHIFT_MANAGEMENT', //'SHIFT_MANAGEMENT',
    items: [
      {
        name: "Shifts",
        icon: "shifts",
        url: "shift-management-shift.html",
        feature: 'ACCOUNT.SHIFT_MANAGEMENT.SHIFT' // 'SHIFT_MANAGEMENT.SHIFT',
      },
      {
        name: 'Projects',
        icon: "projects",
        url: "shift-management-projects.html",
        feature: 'ACCOUNT.SHIFT_MANAGEMENT.PROJECT' // 'SHIFT_MANAGEMENT.PROJECT',
      },
      {
        name: "Users",
        icon: "shift-users",
        url: "space-planner-manage-demand.html",
        // feature: 'SHIFT_MANAGEMENT',
      },
      {
        name: "Rota",
        icon: "rota",
        url: "shift-management-rota.html",
        feature: 'ACCOUNT.SHIFT_MANAGEMENT.ROTA'
      }
    ]
  }
];

const userAppMenu = async () => {

  const languageFile = await loadLanguage();
  const items = [{
    name: "My Freespace App",
    icon: "smartphone.svg",
    url: "#",
    feature: 'USER_APP',
    items: [
      {
        name: languageFile["home.today.schedule"] || "Today's Schedule",
        icon: "webapphome",//"Home.svg",
        url: "todayschedule.html",
        feature: "USER_APP",
        homeOrder: 2,
      },
      {
        name: languageFile["schedule.my"] || "My Schedule",
        icon: "webappcalendar",//"Schedule.svg",
        url: "myschedule.html",
        feature: "USER_APP",
      },
      {
        name: languageFile["circle.my"] || "My Circle",
        icon: "webappusers",//"MyCirclegroup.svg",
        url: "mycircle.html",
        feature: "USER_APP.CIRCLE",
      },
      {
        name: languageFile["account.preference.my"] || "My Preferences",
        icon: "webappuser",//"SinglePerson.svg",
        url: "mypreference.html",
        feature: "USER_APP.PREFERENCES",
      },
    ],
  }];
  return items;
};

const adminMenu = {
  name: "Administration",
  icon: "cogs",
  url: "#",
  feature: 'ACCOUNT.ADMIN',
  items: [
    {
      name: "Manage User Roles",
      icon: "manageusers",
      url: "user-admin.html",
      feature: "ACCOUNT.ADMIN.MANAGE_USERS",
    },
    {
      name: "Manage Account Features",
      icon: "managecheck-circle",
      url: "account-feature.html",
      feature: "ACCOUNT.ADMIN.MANAGE_ACCOUNT",
    }, {
      name: "Switch Account",
      icon: "accountexchange",
      url: "accounts.html",
      feature: 'ACCOUNT.ADMIN.SWITCH_ACCOUNT'
    }
  ]
};
const accessibleMenu = menuItems => {
  const accessible = [];
  _.each(menuItems, menuItem => {
    if (FeatureService.isEnabled(menuItem.feature)) {
      if (menuItem.items) {
        menuItem.items = accessibleMenu(menuItem.items);
      }
      accessible.push(menuItem);
    }
  });
  return accessible;
};
const MenuService = {
  async getMenu() {
    await this.waitForInit();
    return this.menu;
  },
  async __init() {
    console.log('menu service init start');
    if (!this.promise) {
      this.promise = this._getMenu();
    }
    return this.promise;
  },
  async _getMenu() {
    const [tree, displays] = await Promise
      .all([SpaceIO.api.authGetJSON('locations/tree'), SpaceIO.api.authGetJSON('admin/displays')]);
    const addLocationMenu = menuItems => {
      const accessible = [];
      _.each(menuItems, menuItem => {
        if (menuItem.locationMenu) {
          menuItem.item = [locationMenu(tree, menuItem.url, menuItem.feature)];
          if (!tree.showInMenu) {
            // When root node is disabled
            menuItem.item = menuItem.item[0].items;
          }
        } else {
          addLocationMenu(menuItem.items);
        }
      });
      return accessible;
    };
    let screens = {};
    displays.map(d => {
      if (d.alertingEnabled) {
        if (screens[d.locationId]) {
          screens[d.locationId].push(d);
        } else {
          screens[d.locationId] = [d];
        }
      }
    });

    if (FeatureService.isEnabled('USER_APP')) {
      menuMaster.push(...(await userAppMenu()));
    }

    if (Object.keys(screens).length > 0) {
      const signageFeature = 'ACCOUNT.SIGNAGE';
      const findASpaceFeature = 'ACCOUNT.FIND_A_SPACE';
      menuMaster.push({
        name: "Signage",
        icon: "tv",
        url: "#",
        feature: signageFeature,
        items: [
          {
            name: "Signage",
            icon: "tv",
            url: "#",
            feature: signageFeature,
            item: (tree.showInMenu ? [screensMenu(tree, screens, signageFeature)] : screensMenu(tree, screens, signageFeature).items)
          }]
      });
      menuMaster.push({
        name: "Find A Space",
        icon: "tv",
        url: "#",
        feature: findASpaceFeature,
        items: (tree.showInMenu ? [screensMenu(tree, screens, findASpaceFeature)] : screensMenu(tree, screens, findASpaceFeature).items)
      });
    }

    if (FeatureService.isEnabled("ACCOUNT.ADMIN")) {
      menuMaster.push(adminMenu);
    }
    const menu = accessibleMenu(menuMaster);
    addLocationMenu(menu);
    this.menu = menu;
    console.log('menu service init done');
  },
};
BaseService.decorate(MenuService, SpaceIO, FeatureService, BootstrapService);
export default MenuService;
