import SpaceIO from './SpaceIO';
import _ from 'lodash';
import BaseService from './base-service';

const FeatureService = {
  isEnabled(token, locationId) {
    this.ensureInit();
    const feature = _.find(this.enabledFeatures, feature => feature.qualifiedToken === token);
    if (!feature) { return false; }
    if (_.isUndefined(feature.locationIds)) { return true; }
    if (!_.isUndefined(locationId)) { return _.includes(feature.locationIds, locationId); }
    return !_.isEmpty(feature.locationIds);
  },
  isFeatureIdEnabled(featureId) {
    return _.any(this.enabledFeatures, feature => feature.id === featureId);
  },
  isQualifiedTockenEnabled(qualifiedToken = "") {
    return _.any(this.enabledFeatures, token => token.qualifiedToken === qualifiedToken);
  },
  isQualifiedTockenEnabledOnLocation(qualifiedToken = "", locationId) {
    return _.any(this.enabledFeatures, token => token.qualifiedToken === qualifiedToken && token.locationIds && token.locationIds.includes(locationId));
  },
  getAllFeatures() {
    return SpaceIO.api.authGetJSON("features");
  },
  getAccountFeatures() {
    return SpaceIO.api.authGetJSON('accounts/current/features');
  },
  updateAccountFeatures(newFeatureList) {
    return SpaceIO.api.authPostJSON('accounts/current/features', newFeatureList);
  },
  async __init() {
    try {
      console.info('feature service init start');
      this.enabledFeatures = await SpaceIO.api.authGetJSON("features?accessible");
      console.info('feature service init done');
    } catch (err) {
      console.error("error initializing feature", err);
      throw err;
    }
  }
};
BaseService.decorate(FeatureService, SpaceIO);
export default FeatureService;
