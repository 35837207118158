import * as React from "react";

const SvgWebapphome = (props) => (
  <svg
    width={18}
    height={18}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      className="stroke"
      d="M13.5 3h-9a3 3 0 0 0-3 3v7.5a3 3 0 0 0 3 3h9a3 3 0 0 0 3-3V6a3 3 0 0 0-3-3ZM6 1.5v3M12 1.5v3M1.5 7.5h15"
      stroke="#fff"
      strokeOpacity={0.6}
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgWebapphome;
