import React from 'react';
import ReactDOM from 'react-dom';
import SideMenu from './freespace-app-modules/SideMenu/side-menu';
import Header from './freespace-app-modules/Header/header-view';
// import Loader from './../ui/loader';
import BootstrapService from './../../js/services/bootstrap-service';
// import { Container } from './../ui/layout';
import '../../css/freespace-web-app.css';
// import DotPulseLoader from '../ui/dot-loader';
import PageLoader from '../ui/Page-loader/page-loader';

class App extends React.Component {
  state = {
    loading: true,
    date: new Date(),
  }


  componentDidMount() {
    BootstrapService.waitForInit().then(() => {
      this.setState({ loading: false });
    });

  }



  render() {
    return (<div className="wrapper" id='wrap'>
      <Header />
      <SideMenu />
      <div className="content-wrapper">
        {
          this.state.loading ? <PageLoader /> : <this.props.ViewComponent />
          // <Loader margin="20" size="50" color='gray' icon="fa-refresh fa-spin" background='white' /> 
        }
      </div>

    </div>);
  }
}
export default (ViewComponent) => {
  ReactDOM.render(
    (<App ViewComponent={ViewComponent}></App>),
    document.getElementById('app-container')
  );
};
