import React from "react";

class DotPulseLoader extends React.Component {

  constructor(props){
    super(props);
    this.state = {stylePath: 'css/green.css'};
  }

  componentDidMount(){
    this.props.showWhite?
      this.setState({stylePath:'css/white.css'}):'';
  }

  render(){
    return(
      <div style={this.props.style} className="loader">
        <link rel="stylesheet" type="text/css" href={this.state.stylePath} />
        <div className="dot-pulse"></div>
      </div>
    );
  }

}

export default DotPulseLoader;
