import React, {useState} from 'react';
import DotPulseLoader from './dot-loader';

// const actionCall = (e,props) =>{
//   if(props.space) props.action(e,props.space[0]);
//   if(props.action) props.action();
//   else if(props.opensidebar("changeplan")){props.opensidebar("changeplan");}
// };
const PrimaryButton = (props) =>{
  const { buttonPrimaryColor, buttonSecondaryColor, floorPlanButton } = props;
  let style;
  switch (props.buttonType?props.buttonType.toLowerCase() :props.buttonType) {
    case 'small': style='custom-button-small freespace-small-text';
      break;
    default: style=`custom-button-large ${props.noneed? '':"custom-large-button-class"} freespace-large-text`;
  }
  const [ state,setState] = useState({hover:false});
  return(
    <button
      style={{...props,
        margin:props.margin,
        background:state.hover ? `linear-gradient(90deg, ${buttonPrimaryColor} 0%, ${buttonSecondaryColor} 100%)`: `linear-gradient(270deg,${buttonPrimaryColor}  0%, ${buttonSecondaryColor} 100%)`,
      }}
      disabled={props.disabled}
      onMouseOver={()=> setState({hover:true}) }
      onMouseLeave={()=>setState({hover:false})}
      className={` ${style} ${props.class}  ${floorPlanButton?'floor-plan-button':''} freespace-linear-gradient-primary-btn white-color font-bold border-0 border-radius-5 primarybutton-custom-class`}
      onClick={props.action || (()=>props.opensidebar("changeplan"))}>
      <div className='freespace-flex-container'>
        { props.showBookingLoader?
          <div><DotPulseLoader showWhite={true}/></div>:<div>{props.text}</div>
        }
      </div>
    </button>
  );
};

export default PrimaryButton;
