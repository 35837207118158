import * as React from "react";

const SvgSpusers = (props) => (
  <svg
    width={18}
    height={18}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      className="fill"
      d="M2.25 15.75v-1.125h12.375V9H2.25V7.875h7.875V2.25H2.25V1.125H1.125V15.75a1.125 1.125 0 0 0 1.125 1.125h14.625V15.75H2.25Zm11.25-5.625V13.5H2.25v-1.125h9V11.25h-9v-1.125H13.5ZM9 3.375V6.75H2.25V5.625h4.5V4.5h-4.5V3.375H9Z"
      fill={props.iconColor}
      fillOpacity={0.6}
    />
  </svg>
);

export default SvgSpusers;
