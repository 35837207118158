
let IconComponents = {};

IconComponents['building'] = require('./Building').default;
IconComponents['map'] = require('./Map').default;
IconComponents['fire'] = require('./Fire').default;
IconComponents['fa fa-hand-paper-o'] = require('./FaFaHandPaperO').default;
IconComponents['child'] = require('./Child').default;
IconComponents['users'] = require('./Users').default;
IconComponents['Cleanerusers'] = require('./Cleanerusers').default;
IconComponents['pie-chart'] = require('./PieChart').default;
IconComponents['tablet'] = require('./Tablet').default;
IconComponents['file'] = require('./File').default;
IconComponents['stethoscope'] = require('./Stethoscope').default;
IconComponents['dashboard'] = require('./Dashboard').default;
IconComponents['calendar'] = require('./Calendar').default;
IconComponents['spusers'] = require('./Spusers').default;
IconComponents['cogs'] = require('./Cogs').default;
IconComponents['webapphome'] = require('./Webapphome').default;
IconComponents['webappcalendar'] = require('./Webappcalendar').default;
IconComponents['webappusers'] = require('./Webappusers').default;
IconComponents['webappuser'] = require('./Webappuser').default;
IconComponents['tv'] = require('./Tv').default;
IconComponents['manageusers'] = require('./Manageusers').default;
IconComponents['managecheck-circle'] = require('./ManagecheckCircle').default;
IconComponents['accountexchange'] = require('./Accountexchange').default;
IconComponents['menuIconMv'] = require('./MenuIconMv').default;
IconComponents['mvhome'] = require('./Mvhome').default;
IconComponents['mvCalender'] = require('./MvCalender').default;
IconComponents['mvMycircle'] = require('./MvMycircle').default;
IconComponents['mvpreferences'] = require('./Mvpreferences').default;
IconComponents['shifts'] = require('./shifts').default;
IconComponents['projects'] = require('./projects').default;
IconComponents['shift-users'] = require('./shift-users').default;
IconComponents['rota'] = require('./rota').default;
IconComponents['dropdown'] = require('./DropdownArrow').default;
IconComponents['location'] = require('./Location').default;
IconComponents['TickIcon'] = require('./TickIcon').default;

export default IconComponents;