import * as React from "react";

const SvgWebappusers = (props) => (
  <svg
    width={18}
    height={18}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g opacity={0.6} fill="#fff">
      <path 
        className="fill"
        d="M14.063 5.625H3.937A1.69 1.69 0 0 0 2.25 7.313v3.375a1.126 1.126 0 0 0 1.125 1.124v3.938A1.126 1.126 0 0 0 4.5 16.875h2.25a1.126 1.126 0 0 0 1.125-1.125V9H6.75v6.75H4.5v-5.063H3.375V7.313a.563.563 0 0 1 .563-.563h10.124a.562.562 0 0 1 .563.563v3.375H13.5v5.062h-2.25V9h-1.125v6.75a1.126 1.126 0 0 0 1.125 1.125h2.25a1.126 1.126 0 0 0 1.125-1.125v-3.938a1.126 1.126 0 0 0 1.125-1.124V7.312a1.689 1.689 0 0 0-1.688-1.687ZM5.625 5.063a2.25 2.25 0 1 1 0-4.5 2.25 2.25 0 0 1 0 4.5Zm0-3.375a1.125 1.125 0 1 0 0 2.25 1.125 1.125 0 0 0 0-2.25ZM12.375 5.063a2.25 2.25 0 1 1 0-4.5 2.25 2.25 0 0 1 0 4.5Zm0-3.375a1.125 1.125 0 1 0 0 2.25 1.125 1.125 0 0 0 0-2.25Z" />
    </g>
  </svg>
);

export default SvgWebappusers;
