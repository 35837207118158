import _ from 'lodash';
import SpaceIO from './SpaceIO';


const FreespaceappService = {

  //Get list of accessible features
  async accessibleFeature() {
    return await SpaceIO.api.authGetFreespaceapp(`
          query{
            accessibleFeature{
            id
            parentId
            token
            description
            qualifiedToken
          }
        }`
    );
  },

  async getMyLocationPreference() {
    return await SpaceIO.api.authGetFreespaceapp(`
    query{
      me{
        spacePreferences {
          buildingId
          floorId
          building {
            id
            fullName
            name
          }
        }
      }
    }`
    );
  },

  // Get GHOST MODE status
  async getUserGhostModeStatus() {
    return await SpaceIO.api.authGetFreespaceapp(`query{
        userVisibility{
          enable
          showInSchedule
          showInSignage
        }
      }`);
  },

  // Privacy Policy URL
  async getPrivacyPolicy() {
    return await SpaceIO.api.authGetFreespaceapp(`query{
      me{
        account {
          privacyPolicyURL
        }
      }
    }`);
  },

  // Get my circle schedule
  async myCircleSchedule(startdate, enddate) {
    // checkOutTime
    return await SpaceIO.api.authGetFreespaceapp(`query {
      circleSchedule(startDate: ${startdate}, endDate: ${enddate}) {
        user {
          id
          name
          imageUri
        }
        schedule {
          teamId
          date
          type
          requestedBy
          checkInTime
          checkOutTime
          location {
            name
            id
          }
          space {
            name
            id
            floor {
              id
              name
            }
            category {
              name
            }
            srf{
              x
              y
              z
            }
          }
        }
       }
     }`
    );
  },
  async cancelRequest(date, locationId, userId, amenityType = "DESK") {
    const query = `date: ${date},locationId: ${locationId},type: "HOME",userIds: [${userId}],amenityType: "${amenityType}"`;
    return await SpaceIO.api.authPostFreespaceapp(`mutation{
      schedule(${query}){
        date
        result{
          date
          success
      	}
        status
      }
    }`,
    );
  },
  //get all circle schedule
  async getAllCircleSchedule(floorId, date) {
    return await SpaceIO.api.authGetFreespaceapp(`query {
         circleScheduleAll(floorId: ${floorId}, date: ${date}) {
          user {
            id
            name
            imageUri
          }
          schedule {
            teamId
            date
            type
            requestedBy
            checkInTime
            checkOutTime
            location {
              name
              id
            }
            space {
              name
              id
              floor {
                id
                name
              }
              category {
                name
              }
              srf{
                x
                y
                z
              }
            }
          }
         }
       }`
    );
  },

  async checkIfSmallSite(locationId) {
    return await SpaceIO.api.authPostFreespaceapp(`query{
      smartCheckInFeature(locationId: ${locationId})
    }`);
  },

  async getRequestedDeskPreference(startDate, endDate) {
    return await SpaceIO.api.authPostFreespaceapp(`query {
      requestDeskPreferences(startDate: ${startDate}, endDate: ${endDate}){
        id
        name
     }
   }`);
  },

  async getLocationDeskPreference(locationId) {
    return await SpaceIO.api.authPostFreespaceapp(`query{
      locationDeskPreferences(locationId: ${locationId}){
        id
      }
    }`);
  },

  async myCircle() {
    // Get my circle members list
    return await SpaceIO.api.authGetFreespaceapp(`query {
     circle {
        id
        name
        imageUri
        canRequest
        permissionGranted
        spacePreferences{
          building{
            id
            fullName
          }
          department{
            id
            name
          }
        }
      }
    }`
    );
  },

  async getCircleMembers() {
    // Get my circle members list
    return await SpaceIO.api.authGetFreespaceapp(`query {
     circle {
        id
        name
        imageUri
      }
    }`
    );
  },

  // Get my department list by location id if empty location id then get all department list
  async myDepartments(locationid = "") {
    return await SpaceIO.api.authGetFreespaceapp(`query{
      myDepartments(locationId:${locationid}) {
        id
        name
      }
     }`
    );
  },

  // Get Color for the space by location and date
  async getSpaceColorForDay(locationid = "", date = "") {
    return await SpaceIO.api.authGetFreespaceapp(`query{
      workspaceColorPolicy(date: ${date}, locationId: ${locationid}){
        spaceColor{
          name
          value
        }
        locationId
        date
      }
    }`
    );
  },

  // Get my circle search members list
  async SearchUser(searchQuery) {
    return await SpaceIO.api.authGetFreespaceapp(`query {
      searchUser(name: "${searchQuery}") {
        user {
          id
          name
          imageUri
          spacePreferences {
            buildingId
            floorId
            departmentId
          }
        }
        status
      }
    }`
    );
  },

  // Get my circle invite request list
  async invites() {
    return await SpaceIO.api.authGetFreespaceapp(`query {
      invites {
        id
        type
        status
        user {
          id
          name
          imageUri
          spacePreferences {
            buildingId
            departmentId
          }
        }
      }
    }`
    );
  },

  // Get List of users to whom I can reserve a space
  async allowOrRevokeAUserToAssignSpaceForMe(userId, permissionGranted) {
    return await SpaceIO.api.authPostFreespaceapp(`mutation{
      circlePermissions(permissions: [{userId:${userId}, canRequest: ${!permissionGranted}}])
    }`
    );
  },

  // Get notification list
  async notificationHistory() {
    return await SpaceIO.api.authGetFreespaceapp(`query {
      notificationHistory {
        id
        title
        content
        seenAt
        publishedAt
        icon
        data {
          action
        }
      }
    }`
    );
  },

  // Get my building detail properties
  async myBuildingDetail(locationid) {
    return await SpaceIO.api.authGetFreespaceapp(`query {
      building(id: ${locationid}){
        id
        name
        fullName
        address
        todaysOccupancyTrend {
          hour
          occupancy
        }
      }
    }`
    );
  },


  async getamenitiesStatus(startdate, enddate) {
    return await SpaceIO.api.authGetFreespaceapp(`query {
          amenitiesStatus(startDate: ${startdate},endDate: ${enddate}){
            amenity {
              id
              name
              space {
                id
                name
                floor {
                  id
                  name
                  building {
                    id
                    name
                  }
                }
                srf {
                  x
                  y
                }
              }
              checkInTime
              checkOutTime
            }
            amenityType {
              id
              name
              icon
            }
            status
            teamId
            date
            startTime{hour, minute, seconds}
            endTime{hour, minute, seconds}
            requestByUser {
              name
            }
          }
        }`
    );
  },


  async getamenitiesList(locationId) {
    return await SpaceIO.api.authGetFreespaceapp(`query {
      amenityTypes(locationId:${locationId}) {
        id
        name
        icon
         }
        }`
    );
  },


  // Get my schedule by date
  async mySchedule(startdate, enddate) {
    return await SpaceIO.api.authGetFreespaceapp(`query {
      mySchedule(startDate: ${startdate},endDate: ${enddate}){
        date
        day
        type
        allowedToWorkFromOffice
          location {
            id
            name
            }
          }
        }`
    );
  },

  // will return color policy of your allocated space  location on that day
  async workspacepolicy(date) {
    return await SpaceIO.api.authGetFreespaceapp(`query {
            workspacePolicy(date: ${date}){
            workFromHome
            spaceColor{
              name
              value
              rgb
            }
            group{
              id
              name
            }
          }
        }`
    );
  },

  // will return color policy of  allocated space for required location on that day
  async workspaceColorpolicy(date, locationId) {
    return await SpaceIO.api.authGetFreespaceapp(`
        query{
          workspaceColorPolicy(date: ${date}, locationId: ${locationId}){
            spaceColor {
              name
              value
              rgb
            }
            locationId
            date
          }
        }
    `);
  },

  async getEnabledLocationsForFeatures(features) {
    return await SpaceIO.api.authGetFreespaceapp(`query{
      featureLocation(qualifiedTokens: ["${features.join('", "')}"]){
        key
        value
        listValue
      }
    }`);
  },

  async getPreRequisiteData(qtype) {
    return await SpaceIO.api.authGetFreespaceapp(`query{
      questionnaireWithRuleSet(qtype: "${qtype}"){
        locationId
        date
        qtype
        linkForQuestionnaire
        messages {
          FAIL
          PASS
        }
        lastStatus
        timeLeft
      }
    }`);
  },

  // Get BuildingList  current data
  async BuildingList() {
    return await SpaceIO.api.authGetFreespaceapp(`query {
                myBuildings{
                    id
                    parentId
                    name
                    fullName
                    address
                    startTime
                    endTime
                    city {
                      name
                      id
                    }
                 }
            }`
    );
  },

  async getBuildingsCommonToUsers(userIds) {
    const param = userIds && !_.isEmpty(userIds) ? `(userIds: [${userIds}])` : "";
    return await SpaceIO.api.authGetFreespaceapp(`query {
      myBuildings${param} {
        id
        name
        shortName
        fullName
        type
        timeZone
      }
    }`);
  },

  async getBuildingsCommonToTeam(teamId) {
    const param = teamId ? `(teamId: ${teamId})` : "";
    return await SpaceIO.api.authGetFreespaceapp(`query {
      myBuildings${param} {
        id
        name
        shortName
        fullName
        type
        timeZone
        city {
          name
          id
        }
      }
    }`);
  },

  async historicalOccupancyTrend(locationid, daysOfTheWeek, numberOfWeeks) {
    return await SpaceIO.api.authGetFreespaceapp(`query {
      historicalOccupancyTrend(id: ${locationid},daysOfTheWeek: ${daysOfTheWeek}, numberOfWeeks: ${numberOfWeeks}) {
            hour
            occupancy
          }
        }`
    );
  },


  // Get recent Event history
  async spotserialevents(spotid) {
    return await SpaceIO.api.authGetFreespaceapp(`query {
        spot(serialId:${spotid},locationInfo: {location:{ latitude: 51.50653458, longitude: -0.14384100}}) {
          availability {
            value
            reasonCode
          }
          recentEvents{
            type
            tzEpoch
            utcEpoch
          }
          color {
            name
            rgb
          }
          checkIn{
              status
          }
          space {
            id
            name
            floorId
            floor{
              building{
                timeZone
              }
            }
            department {
              id
              name
            }
          }
        }
        }`
    );
  },


  async allocatedSpaceDetail(startdate, enddate) {
    return await SpaceIO.api.authGetFreespaceapp(`query {
     allocatedSpace(startDate:${startdate},endDate:${enddate}) {
        checkInTime
        checkOutTime
        date
        space {
          id
          category {
            name
          }
          srf {
            x
            y
            z
          }
          floor {
            id
            name
            building {
              id
              name
              timeZone
            }
          }
        }
      }
      }`
    );
  },

  async getAvilableLanguages() {
    return await SpaceIO.api.authGetFreespaceapp(`query{
      availableLanguages
    }`);
  },

  // getFloorPlan
  async getFloorPlan(floorID) {
    return await SpaceIO.api.authGetFreespaceapp(`
    query{
      floor(id: ${floorID}){
        id
        name
        image {
          url
          width
          height
          pixelsPerMeter
        }
        buildingId
      }
    }
  `);

  },

  async getFloorDetail(buildingId, amenityType = "Desk") {
    return await SpaceIO.api.authGetFreespaceapp(`query{
      allFloorIds(locationId: ${buildingId}, amenityType: "${amenityType}"){
        id
        parentId
        name
        fullName
      }
    }`);
  },

  async MyDetail() {
    return await SpaceIO.api.authGetFreespaceapp(`
    query{
      me{
        id
        name
        imageUri
        langPreferences
        spacePreferences {
              buildingId
              departmentId
              building {
                id
                name
                fullName
                address
                timeZone
              }
              department {
                id
                name
              }

            }
        langPreferences
        deskPreferences{
          id
          name
        }
      }
    }`
    );
  },

  async getSpacesOnFloor(floorId, dateStringYYYYMMDD, includeAllSpaces = false) {     // Id can be Building, Floor as per requirement
    return await SpaceIO.api.authGetFreespaceapp(`query{
      spacesOnFloor(locationId: ${parseInt(floorId)}, all: ${includeAllSpaces}, date: ${dateStringYYYYMMDD}){
        id
        name
        category{
          name
        }
        department{
          name
          id
        }
        floorId
        srf {
          x
          y
          z
        }
        floor {
          id
          parentId
          name
          shortName
          fullName
          type
          level
          markerSize
          buildingId
          building {
            id
            parentId
            name
            shortName
            fullName
          }
        }
        available
      }
    }`);
  },

  async getAllSpacesOfFloor(floorId, dateStringYYYYMMDD, includeAllSpaces = false) {     // Id can be Building, Floor as per requirement
    return await SpaceIO.api.authGetFreespaceapp(`query{
      spacesOnFloor(locationId: ${floorId}, all: ${includeAllSpaces}, date: ${dateStringYYYYMMDD}){
        id
        name
        floorId
        srf {
          x
          y
          z
        }
        available
      }
    }`);
  },

  async getDeskOptions(locationId) {
    return await SpaceIO.api.authPostFreespaceapp(`query{
      deskAttributes(locationId: ${locationId}){
        id
        name
        attributes{
          name
          id
        }
      }
    }`);
  },

  async getMyTeamsInfo() {
    return await SpaceIO.api.authGetFreespaceapp(`query{
      myTeamInfo{
        team {
          id
          name
        }
        member {
          user {
            id
            accountId
            name
            imageUri
            email
          }
          admin
        }
      }
    }`);
  },

  async getTeamSchedule(startDate, endDate, teamId) {

    const santitizedTeamId = !teamId ? `[]` : `[${teamId}]`;
    return await SpaceIO.api.authGetFreespaceapp(`query{
      myTeamSchedule(teamIds: ${santitizedTeamId}, startDate: ${startDate}, endDate: ${endDate}){
        team {
            id
            name
            createdAt
            createdById
        }
        schedule {
            date
            locationId
            location {
                id
                name
            }
            teamRequestId
            departmentType

        }
        member{
               user {
                    id
                    name
                    imageUri
              }
              admin
              schedule {
                date
                status
                spaceId
                checkInTime
                checkOutTime
                space {
                  id
                  name
                  available
                  floor{
                    id
                    name
                    fullName
                  }
                }
                location {
                    id
                    parentId
                    name
                }
              }
          }

    }
    }`);
  },


  //POST APIS---------------------------------------------------


  async spacePreferences(departmentId, buildingId) {
    return await SpaceIO.api.authPostFreespaceapp(`mutation {
      spacePreferences(departmentId:${departmentId},buildingId:${buildingId}) {
        buildingId
        departmentId
        building {
          id
          parentId
          name
          fullName
          timeZone
        }
        department {
          id
          name
        }
      }
      }`,
    );
  },

  async checkoutFromSpace(spaceId) {
    return await SpaceIO.api.authPostFreespaceapp(`mutation{
      checkOut(spaceId: ${spaceId}){
        statusCode
        status
        message
      }
    }`);
  },



  async setLanguagePreference(languageCode) {
    return await SpaceIO.api.authPostFreespaceapp(`mutation{
      languagePreferences(languageCode: "${languageCode}")
    }`);
  },

  async setUserGhostModeStatus(enable) {
    console.log(enable, !enable);
    return await SpaceIO.api.authPostFreespaceapp(`mutation{
      userVisibility(enable: ${enable}, showInSchedule: ${!enable}, showInSignage: ${!enable}){
        enable
        showInSchedule
        showInSignage
      }
    }`);
  },

  async inviteUser(userId) {
    return await SpaceIO.api.authPostFreespaceapp(`mutation {
      inviteUser(userId: ${userId}) {
          id
          type
          user {
            id
            name
            imageUri
            spacePreferences {
              buildingId
              floorId
              departmentId
            }
          }
          status
        }
      }`,
    );
  },

  //Action on user circle request
  async UserActionRequest(userId, status) {
    return await SpaceIO.api.authPostFreespaceapp(`mutation {
      actionOnCircle(userId: ${userId}, status: ${status} ) {
        id
        type
        user {
          id
          name
          imageUri
          spacePreferences {
            buildingId
            floorId
            departmentId
          }
        }
        status
      }

      }`,
    );
  },

  // Auto CheckIn
  async checkInForSmallSite(spaceId) {
    return await SpaceIO.api.authPostFreespaceapp(`mutation{
      autoCheckIn(spaceId: ${spaceId}){
          statusCode
          status
          message
      }
    }`);
  },

  async saveDeskPreferences(locationId, attrbutes) {
    const arrAttributes = attrbutes ? `[${attrbutes}]` : `[]`;
    return await SpaceIO.api.authPostFreespaceapp(`mutation{
      deskPreferences(locationId: ${locationId}, attributes:${arrAttributes} )
    }`);
  },

  async getDemandData(locationId, forwardDays = 3, backwardDays = 3) {
    return await SpaceIO.api.authGetFreespaceapp(`query {
      spaceSummary(locationId: ${locationId}, forwardDays:${forwardDays},backwardDays:${backwardDays}, includeWeekends: false) {
            date
            released
            demand
            checkedIn
          }
        }`);
  },

  async getQuestionnaireResult(params, date) {
    return await SpaceIO.api.authGetFreespaceapp(`mutation{
      questionnaire(parameters: ${params}, date: ${date}){
        success
        error
        message
      }
    }`);
  },

  async getQuestionnaireData(locationId, date) {
    return await SpaceIO.api.authGetFreespaceapp(`query{
      questionnaire(locatinId: ${locationId}, date: ${date}){
        data {
          FAIL
          PASS
        }
        link
      }
    }`);
  },


  async getScheduleQuestionnaireData(locationId, date, qtype) {
    return await SpaceIO.api.authGetFreespaceapp(`query{
      questionnaire(locatinId: ${locationId}, date: ${date}){
        data {
          FAIL
          PASS
        }
        link
      }
      questionnairePreRequisite(locationId: ${locationId}, qtype: "${qtype}", date: ${date}){
        locationId
        date
        qtype
        linkForQuestionnaire
        messages{
          FAIL
          PASS
        }
        lastStatus
        timeLeft
        submitted
      }
    }`);
  },

  async getThresDate() {
    return await SpaceIO.api.authPostFreespaceapp(`query{
      anonymizationPeriod
    }`
    );
  },

  async getrequestEligibilityDates(location, startDate, endDate) {
    return await SpaceIO.api.authGetFreespaceapp(`query{
      requestEligibility(locationId: ${location}, startDate: ${startDate}, endDate: ${endDate}){
        key
        value
        listValue
      }
    }`);
  },

  async setSchedulePlan(date, locationId, status, attributes, startTime = null, endTime = null, selectedSpaceId = null, amenityType, dates = []) {
    const santitizedAttributes = attributes ? `[${attributes}]` : !selectedSpaceId ? `[]` : null;

    const formatedStart = startTime ? `"${startTime}"` : null;
    const formatedEnd = endTime ? `"${endTime}"` : null;
    const query = ` dates: [${dates.length > 0 ? dates : date}],locationId: ${locationId},type: "${status}"${santitizedAttributes ? `,attributes:${santitizedAttributes}` : ''}${startTime ? `,startTime: ${formatedStart}` : ''}${endTime ? `, endTime: ${formatedEnd}` : ''}${selectedSpaceId ? `,selectedSpaceId: ${selectedSpaceId}` : ''}, amenityType: "${amenityType}"`;
    return await SpaceIO.api.authPostFreespaceapp(`mutation {
        schedule(${query}) {
          result{
            date
            success
          }
          date
          day
          type
          location {
            id
          }
          isAllocatedImmediately
          status
        }
      }`,
    );
  },


  async assignSpaceForCollegueNearMe(date, locationId, userId, bookNearbySpace, selectedSpaceId, startTime = null, endTime = null) {
    const formatedStart = startTime ? `"${startTime}"` : null;
    const formatedEnd = endTime ? `"${endTime}"` : null;
    const query = `date: ${date},locationId: ${locationId},type: "OFFICE",userIds: ${userId},proximity:${bookNearbySpace}${startTime ? `,startTime: ${formatedStart}` : ''}${endTime ? `, endTime: ${formatedEnd}` : ''}${selectedSpaceId ? `, selectedSpaceId: ${selectedSpaceId}` : ''}`;
    return await SpaceIO.api.authPostFreespaceapp(`mutation{
      schedule(${query}){
        date
        allowedToWorkFromOffice
        workFromOfficeMessage
        requestedBy
        isAllocatedImmediately
        status
        checkInTime
      }
    }`,
    );
  },

  // Checkin to Space by Serial id
  async CheckIntoSpace(spotid) {
    return await SpaceIO.api.authPostFreespaceapp(`mutation {
      checkIn(serialId:${spotid},locationInfo:{location:{ latitude: 51.50653458, longitude: -0.14384100}}) {
        statusCode
        status
        message
      }
    }`
    );
  },

  async notificationSeen(NotificationIds) {
    return await SpaceIO.api.authPostFreespaceapp(`mutation {
      notificationSeen(notificationids: [${NotificationIds}]) {
        id
        title
        content
        seenAt
        publishedAt
        icon
      }
    }`
    );
  },

  async createTeamPlan(teamId, dates, locationId, type = "OFFICE", amenityType = "DESK", departmentType, departmentId = null, teamRequestId) {
    const sanitizedDates = dates ? `[${dates}]` : `[]`;
    const sanitizedDepartmentType = departmentType ? `departmentType: "${departmentType}"` : "";
    const sanitizedTeamRequestId = type === "OFFICE" ? "" : `teamRequestId: ${teamRequestId}`;
    const sanitizedDeptId = departmentId ? `departmentId: ${departmentId}` : "";
    return await SpaceIO.api.authGetFreespaceapp(`mutation {
      teamSchedulePlan(
        teamId: ${teamId}
        dates: ${sanitizedDates}
        locationId: ${locationId}
        type: "${type}"
        amenityType: "${amenityType}"
        ${sanitizedDeptId}
        ${sanitizedDepartmentType}
        ${sanitizedTeamRequestId}
      ) {
        result{
          date
          success
      }
      }
    }`);
  },

  async createTeam(teamName) {
    const santizedTeamName = String(teamName).toString();
    return await SpaceIO.api.authPostFreespaceapp(`mutation{
      createTeam(name: "${santizedTeamName}"){
        createdAt
        updatedAt
        createdById
        id
        name
      }
    }`);
  },

  async deleteTeam(teamId) {
    const santizedTeamId = parseInt(teamId);
    return await SpaceIO.api.authPostFreespaceapp(`mutation {
      deleteTeam(id: ${santizedTeamId}) {
        status
      }
    }`);
  },

  async toggleUserAccessAsAdmin(teamId, userId, isAdmin = true) {
    const santizedTeamId = parseInt(teamId);
    const santizedUserId = parseInt(userId);
    return await SpaceIO.api.authPostFreespaceapp(`mutation{
      changeTeamMemberPermission(id: ${santizedTeamId}, admin: ${isAdmin}, userId: ${santizedUserId}){
        user {
          id
          name
          email
        }
        admin
      }
    }`);
  },

  async removeUsersOrExitFromTeam(teamId, userIds) {
    const santizedTeamId = parseInt(teamId);
    const santizedUserIds = `[${userIds}]`;
    return await SpaceIO.api.authPostFreespaceapp(`mutation{
      removeTeamMember(id: ${santizedTeamId}, userIds: ${santizedUserIds}){
        status
      }
    }`);
  },

  async addMembersToTeam(teamId, userIds) {
    const santizedTeamId = parseInt(teamId);
    const santizedUserIds = `[${userIds}]`;
    return await SpaceIO.api.authPostFreespaceapp(`mutation{
      addTeamMember(id: ${santizedTeamId}, userIds: ${santizedUserIds}){
        user {
          id
          name
          email
        }
        admin
      }
    }`);
  },

};

export default FreespaceappService;
