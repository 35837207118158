import * as React from "react";

const SvgBuilding = (props) => (
  <svg
    width={18}
    height={18}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      className="fill"
      d="M15.75 5.625h-3.375v-2.25A1.125 1.125 0 0 0 11.25 2.25h-4.5a1.125 1.125 0 0 0-1.125 1.125v2.25H2.25A1.125 1.125 0 0 0 1.125 6.75v7.875A1.125 1.125 0 0 0 2.25 15.75h13.5a1.125 1.125 0 0 0 1.125-1.125V6.75a1.125 1.125 0 0 0-1.125-1.125Zm-9-2.25h4.5v2.25h-4.5v-2.25Zm-4.5 11.25V6.75h13.5v7.875H2.25Z"
      fill={props.iconColor}
      fillOpacity={0.6}
    />
  </svg>
);

export default SvgBuilding;
