import * as React from "react";

const SvgMvMycircle = (props) => (
  <svg
    width={18}
    height={18}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g opacity={0.7} fill="#444">
      <path
        className="fill"
        d="M16.875 16.875H15.75v-2.813a2.816 2.816 0 0 0-2.813-2.812v-1.125a3.942 3.942 0 0 1 3.938 3.938v2.812ZM12.375 16.875H11.25v-2.813a2.816 2.816 0 0 0-2.813-2.812H5.063a2.816 2.816 0 0 0-2.813 2.813v2.812H1.125v-2.813a3.942 3.942 0 0 1 3.938-3.937h3.375a3.942 3.942 0 0 1 3.937 3.938v2.812ZM11.25 1.125V2.25a2.812 2.812 0 1 1 0 5.625V9a3.937 3.937 0 1 0 0-7.875ZM6.75 2.25a2.812 2.812 0 1 1 0 5.625 2.812 2.812 0 0 1 0-5.625Zm0-1.125A3.938 3.938 0 1 0 6.75 9a3.938 3.938 0 0 0 0-7.875Z" />
    </g>
  </svg>
);

export default SvgMvMycircle;
