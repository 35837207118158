async function waitForInit() {
  await this.__initPromise;
}

function ensureInit() {
  if (!this.__inited) {
    throw new Error('Service is not initialized');
  }
}

async function decorate(Service, ...dependencies) {
  try {
    Service.waitForInit = waitForInit;
    Service.ensureInit = ensureInit;
    Service.__initPromise = new Promise(async resolve => {
      // setTimeout(async () => {
      await Promise.all(dependencies.map(service => !service.waitForInit || service.waitForInit()));
      if (Service.__init) {
        await Service.__init();
      }
      Service.__inited = true;
      resolve();
      // }, 1000);
    });
  } catch (err) {
    console.error('error initialising service', err);
  }
}

export default { decorate };
