import _ from "lodash";

const cardItems = [
  {
    title: 'Unavailable',
    color: '#5d5d5d',
    hex: '#5d5d5d'
  },
  {
    title: 'Available',
    color: '#38D68B',
    hex: '#38D68B'
  }
];

const cardItemsV2 = [
  {
    title: 'Unavailable',
    color: '#BBBBBB',
    hex: '#BBBBBB'
  },
  {
    title: 'Available',
    color: '#4ECB71',
    hex: '#4ECB71'
  }
];

const MY_SCHEDULE_PLAN_DAYS = 29;
const RING_SIZE_THRESHOLD = 45;
const FURNITURE_SIZE = 23;
const DEFAULT_CALENDAR_RETURN_VAL = {
  'HOURS': 17,
  'MINUTES': 30,
  'SECONDS': 0
};

const SPACE_STATUS = ['USED', 'CHECKED_IN', 'CHECKED_OUT', 'NOT_CHECKED_IN_USER', 'CHECKED_IN_USER', 'AVAILABLE', 'ALLOCATED_TO_OTHER', 'ALLOCATED_OTHER_SPACE', 'WRONG_COLOR', 'DONOTUSE', 'BLOCKED_SPACE'];

const NO_IMAGE_USER = 'https://cdn.afreespace.com/images/no-image-user.png';

const NO_DEPARTMENT_OPTION = {
  id: -1,
  name: "None",
};

const FLEX_SPACE = {
  id: -1,
  name: "Flex Space",
};

const FLOOR_SPACE_SELECTION_COMMON_CONFIGURATION = {
  options: { limitToBounds: false, minScale: 0.125, maxScale: 32 },
};

const FLOOR_SPACE_SELECTION_CONFIGURATION = {
  zoomedView: {
    ...FLOOR_SPACE_SELECTION_COMMON_CONFIGURATION,
    wheel: { step: 5, disabled: true } // Enable kar sakte he by setting false, but scroll pe modal scroll ke jagah floorplan zoom hoga!
  },
  normalView: {
    ...FLOOR_SPACE_SELECTION_COMMON_CONFIGURATION,
    wheel: { step: 0, disabled: true },
  }
};

const LONELY_MAN_ILLUSION = {
  path: 'images/LonelyManIllustration.png',
  defaultWidth: "332px",
};

const RESERVATION_DURATION_OPTIONS = [{ key: "FULL_DAY", value: "FULL" }, { key: "CUSTOM", value: "CUSTOM" }];

const FLAT_PICKER_OPTIONS = {
  noCalendar: true,
  // enableSeconds: true,
  altFormat: "F j, Y",
  // dateFormat: "h:i:i K",
  dateFormat: "h:i K",
  time_24hr: true,
  enableTime: true
};

const SPACE_TYPE = {
  DESK: "Desk",
  CAR_PARK: "Car_park",
};

const CATEGORY_TO_SPACE = {
  Desks: "Desk",
};

const TEAM_NAME_REGEX = "^[a-zA-Z0-9-_][a-zA-Z0-9-_ ]{1,30}[a-zA-Z0-9]$";
/*
 ^[a-zA-Z0-9-_]: String should only start with A-z,a-z,0-9,-,_
 [a-zA-Z0-9-_ ]{1,30}: String upto length-1 include A-z,a-z,0-9,-,_ and space and limit max string length to 32
 [a-zA-Z0-9]$: String should only end with A-z,a-z,0-9
*/

const LOCALSTORAGE_KEYS = {
  AUTH_KEY_NAME: "spaceio-key",
  APP_VERSION_KEY: "appVersion",
  SPACEIO_LAST_ACTION: "spaceio-lastAction",
  SPACEIO_REFRESH_TOKEN: "spaceio-refresh-token",
  SPACEIO_IDLE_TIMEOUT_STATUS: "spaceio-idle-timeout-status",
  SPACEIO_USER: "spaceio-user",
};

const MarkerContainerStyle = {
  width: 24,
  height: 24,
  position: 'absolute',
  zIndex: 5,
  borderRadius: "50%",
  border: '2px solid #FFF',
  boxShadow: 'rgba(0, 0, 0, 0.25) 1px 2px 2px',
};

//METHODS
const noop = () => { };

const getColors = () => {
  return { unavailable: cardItemsV2[0].hex, selfUser: cardItemsV2[1].hex, available: cardItemsV2[1].hex };
};

const formatAMPM = (date, is24 = false, languageFile) => {
  let hours = date.hour;
  let minutes = date.minute;
  let strTime = "";
  if (!is24) {
    let ampm = hours >= 12 ? (languageFile ? languageFile["general.time.pm"] : 'PM') : (languageFile ? languageFile["general.time.am"] : 'AM');
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    strTime = hours + ':' + minutes + ' ' + ampm;
  }
  else {
    hours = hours ? hours : 24;
    hours = hours < 10 ? '0' + hours : hours;
    minutes = minutes < 10 ? '0' + minutes : minutes;
    strTime = hours + ':' + minutes + '';
  }
  return strTime;
};

const getLatestAmenity = (amenities, type=null) => { // Returns latest amenity where user isnt checked in.
  let amenityArray;
  if(!_.isEmpty(amenities)){
    switch(type){
      case "Desk": amenityArray = amenities.filter(obj => obj.amenityType && obj.amenityType.name && obj.amenityType.name === "Desk");
        break;

      case "CarPark" : amenityArray = amenities.filter(obj => obj.amenityType && obj.amenityType.name && obj.amenityType.name === "Car_park");
        break;

      default : amenityArray = amenities;

    }
  }else return [];

  const allocatedAmenity = [];
  const confirmedAmenity = [];
  for (let amenity of amenityArray) {
    if (amenity && amenity.status && (amenity.status == "CONFIRMED" || amenity.status == "PENDING")) confirmedAmenity.push(amenity);
    if (amenity && amenity.amenity) {
      if (!amenity.amenity.checkOutTime) {
        allocatedAmenity.push(amenity);
      }
    }
  }
  return _.isEmpty(allocatedAmenity) ? confirmedAmenity : allocatedAmenity;
};


const validateTeamNameInput = (textString = "", regex = TEAM_NAME_REGEX) => {
  const validation = textString.match(regex);
  if (validation)
    return true;
  return false;
};

export{
  cardItems, MY_SCHEDULE_PLAN_DAYS, FURNITURE_SIZE,
  RING_SIZE_THRESHOLD, DEFAULT_CALENDAR_RETURN_VAL,
  NO_IMAGE_USER, SPACE_STATUS, noop, NO_DEPARTMENT_OPTION, RESERVATION_DURATION_OPTIONS,
  FLAT_PICKER_OPTIONS, formatAMPM, LONELY_MAN_ILLUSION, LOCALSTORAGE_KEYS, FLOOR_SPACE_SELECTION_CONFIGURATION,
  getColors, SPACE_TYPE, cardItemsV2, MarkerContainerStyle, CATEGORY_TO_SPACE, TEAM_NAME_REGEX, getLatestAmenity, validateTeamNameInput, FLEX_SPACE
};
