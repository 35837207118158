import _ from 'lodash';
import SpaceIO from './SpaceIO';
import BaseService from './base-service';

const UserService = {
  getSelf() {
    return SpaceIO.api.authGetJSON('self');
  },
  getUsers() {
    return SpaceIO.api.authGetJSON('admin/users');
  },
  getNonOfficeUser(){
    const role_id = { "role": ["0", "1", "2", "3", "4", "5", "6", "8", "9", "10", "11", "12", "13", "14", "15", "16","17","18","19","20","21","22","23"] };
    return SpaceIO.api.authPostJSON('users/fetch', role_id);
  },
  getFilterUser(obj){       
    return SpaceIO.api.authPostJSON('users/fetch', obj);
  },
  getCurrentUser() {
    return SpaceIO.user;
  },
  getCurrentUserRoles() {
    return _.map(this.getCurrentUser().roles, role => role.title);
  },
  isLegacyUser() {
    return _.includes(this.getCurrentUserRoles(), "Legacy User");
  },
  isOfficeUser() {
    return _.includes(this.getCurrentUserRoles(), "Office User");
  },
  getRoles() {
    return SpaceIO.api.authGetJSON('roles');
  },
  saveUserRoles(userRoles) {
    return SpaceIO.api.authPostJSON('roles/users', userRoles);
  },
  getPreference(key) {
    return new Promise((resolve, reject) => {
      SpaceIO.api.authGetJSON('admin/pageDescUserPrefs').then(preferences => {
        const preference = _.find(preferences, preference => preference.pageDescription.name == key);
        if (preference) {
          resolve({
            value: preference.pageDescription.value,
            hide: preference.hideFlag
          });
        } else {
          reject('Key not found');
        }

      });
    });
  },
  savePreference(key, hide) {
    return SpaceIO.api.authPostJSON('admin/pageDescUserPrefs', {
      hideFlag: hide,
      pageDescription: {
        name: key
      }
    });
  },
  delete(){

  },
  __init(){
    console.info('uuser service inited');
  }
};
BaseService.decorate(UserService, SpaceIO);
export default UserService;
