import * as React from "react";

const SvgTv = (props) => (
  <svg
    width={16}
    height={16}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g opacity={0.6} clipPath="url(#tv_svg__a)">
      <path
        className="fill"
        d="M13.03 1H2.3A2.3 2.3 0 0 0 0 3.3V9.43a2.3 2.3 0 0 0 2.3 2.3h4.599v1.533H3.832a.767.767 0 0 0 0 1.533h7.666a.767.767 0 0 0 0-1.533H8.431v-1.533h4.598a2.3 2.3 0 0 0 2.3-2.3V3.3a2.3 2.3 0 0 0-2.3-2.3Zm.767 8.431a.767.767 0 0 1-.767.767H2.3a.767.767 0 0 1-.767-.767V3.3a.767.767 0 0 1 .767-.767h10.73a.766.766 0 0 1 .767.767V9.43Z"
        fill="#fff"
      />
    </g>
    <defs>
      <clipPath id="tv_svg__a">
        <path fill="#fff" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgTv;
