import React from "react";
import './page-loader.css';

function PageLoader() {
  return (
    <div className="page-Loaders">
      <div className="snippet" data-title=".dot-carousel">
        <div className="stage">
          <div className="dot-carousel"></div>
        </div>
      </div>
    </div>
  );
}

export default PageLoader;