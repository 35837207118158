import * as React from "react";

const SvgPieChart = (props) => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      className="stroke"
      d="M7.5 6.75H4.5" stroke="white" strokeOpacity="0.6" strokeWidth="1.125" strokeLinecap="round" strokeLinejoin="round" />
    <path
      className="stroke"
      d="M11.625 8.25C11.1277 8.25 10.6508 8.05246 10.2992 7.70083C9.94754 7.34919 9.75 6.87228 9.75 6.375C9.75 5.87772 9.94754 5.40081 10.2992 5.04917C10.6508 4.69754 11.1277 4.5 11.625 4.5C12.1223 4.5 12.5992 4.69754 12.9508 5.04917C13.3025 5.40081 13.5 5.87772 13.5 6.375C13.5 6.87228 13.3025 7.34919 12.9508 7.70083C12.5992 8.05246 12.1223 8.25 11.625 8.25Z" stroke="white" strokeOpacity="0.6" strokeWidth="1.125" strokeLinecap="round" strokeLinejoin="round" />
    <path
      className="stroke"
      d="M4.5 4.5H6.75" stroke="white" strokeOpacity="0.6" strokeWidth="1.125" strokeLinecap="round" strokeLinejoin="round" />
    <path
      className="stroke"
      d="M13.5 13.5L10.125 11.25L8.25 12.75L4.5 9.75" stroke="white" strokeOpacity="0.6" strokeWidth="1.125" strokeLinecap="round" strokeLinejoin="round" />
    <path
      className="stroke"
      d="M2.25 15.3V2.7C2.25 2.58065 2.29741 2.46619 2.3818 2.3818C2.46619 2.29741 2.58065 2.25 2.7 2.25H15.3C15.4193 2.25 15.5338 2.29741 15.6182 2.3818C15.7026 2.46619 15.75 2.58065 15.75 2.7V15.3C15.75 15.4193 15.7026 15.5338 15.6182 15.6182C15.5338 15.7026 15.4193 15.75 15.3 15.75H2.7C2.58065 15.75 2.46619 15.7026 2.3818 15.6182C2.29741 15.5338 2.25 15.4193 2.25 15.3V15.3Z" stroke="white" strokeOpacity="0.6" strokeWidth="1.125" />
  </svg>
);
export default SvgPieChart;
