import React from 'react';
import Modal from 'react-bootstrap-modal';

import './index.css';

// Docs and examples: https://react-bootstrap.github.io/components/modal/

const CommonModal = ({ headerChildren, bodychildren, footerChildrens, handleCloseModal, modalType='input', show, fullscreen = false, size = 'sm', centerAlignHeader = false}) => {

  return (
    <React.Fragment>
      <>

        <Modal className={`custom-class`} show={show} onHide={handleCloseModal}
          dialogClassName={`${modalType}-modal`}
          fullscreen={fullscreen}
          size={size}
          aria-labelledby="contained-modal-title-vcenter"
          centered={true}>
          <img className='cross-icon' src='images/icons/Group.svg' onClick={handleCloseModal} />
          <Modal.Header>
            <div style={centerAlignHeader ? { textAlign: "center" } : {}}>{headerChildren}</div>
          </Modal.Header>
          <Modal.Body>{bodychildren}</Modal.Body>
          <Modal.Footer>{footerChildrens}</Modal.Footer>
        </Modal>
      </>
    </React.Fragment>
  );
};


export default CommonModal;
