import * as React from "react";

const SvgCalendar = (props) => (
  <svg
    width={18}
    height={18}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      className="fill"
      d="M15.487 9.66a5.057 5.057 0 1 0-7.833-6.352 3.373 3.373 0 1 0-4.813 4.13 3.936 3.936 0 1 0 4.497 6.46 3.374 3.374 0 0 0 6.725-.398c0-.069-.007-.135-.01-.203a1.961 1.961 0 1 0 1.434-3.638Zm-3.674-7.41a3.937 3.937 0 1 1 0 7.875 3.937 3.937 0 0 1 0-7.875Zm-7.313 0a2.25 2.25 0 1 1 0 4.5 2.25 2.25 0 0 1 0-4.5Zm6.188 13.5a2.25 2.25 0 1 1 0-4.5 2.25 2.25 0 0 1 0 4.5Z"
      fill={props.iconColor}
      fillOpacity={0.6}
    />
  </svg>
);

export default SvgCalendar;
