export default {
  control: (styles, { hasValue, isDisabled, isFocused }) => ({
    ...styles,
    margin: "4px 0 0",
    padding: "0px",
    borderRadius: "8px",
    border: hasValue
      ? "solid 1px solid rgb(199, 199, 199)  !important"
      : "solid 1px rgb(199, 199, 199) !important",
    backgroundColor: hasValue
      ? "background-color: rgb(255, 255, 255)"
      : "#f7f7f7",
    borderColor: hasValue
      ? isDisabled ? "hsl(0,0%,90%);" : isFocused ? "rgba(68, 68, 68,0.3)  !important" : "rgba(68, 68, 68,0.3)"
      : "rgba(68, 68, 68,0.3) !important",
    boxShadow: isFocused ? "0 0 0 1px var(--primary-green) !important" : "",
    fontFamily: "var(--font-family-nunito)",
    fontSize: "var(--size-14)",
    cursor: 'pointer',
  }),
  menu: (styles) => ({
    ...styles,
    fontFamily: "var(--font-family-nunito)",
    fontSize: "var(--size-14)",
    zIndex: 999,
    backgroundColor: "#ffffff",
    height: '42px',
    padding: "8px 0",
    border: "0px",
    "margin-top": "1px"
  }),
  multiValue: (styles) => ({
    ...styles,
    // height: "30px", //"40px",
    fontFamily: "var(--font-family-nunito)",
    fontSize: "var(--size-14)",
    margin: "0px 8px 1px 0",
    padding: '0px', // "4px 4px",
    borderRadius: "20px",
    backgroundColor: "rgba(68, 68, 68, 0.1)", // "rgba(29, 34, 82, 0.1)",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.5",
    letterSpacing: "0px",
    textAlign: "left",
    color: "#000000",
    cursor: "pointer",
  }),
  multiValueLabel: (styles) => ({
    ...styles,
    // height: "28px",
    fontFamily: "var(--font-family-nunito)",
    fontSize: "var(--size-14)",
    margin: '0px', // "0px 4px 0px 0",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.5",
    padding: "0",
    paddingLeft: "0"
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    fontFamily: "var(--font-family-nunito)",
    fontSize: "var(--size-14)",
    paddingLeft: '0px',
    paddingRight: '5px',
    color: data.color,
    opacity: 0.5,
    // display: 'none',
    ":hover": {
      // backgroundColor: "rgba(68, 68, 68, 0.1)", // "#FFF",
      color: "#000000",
      opacity: 1,
    },
  }),
  indicatorSeparator: (styles) => ({
    ...styles,
    display: 'none'
  }),
  groupHeading: base => ({
    ...base,
    fontFamily: "var(--font-family-nunito)",
    fontSize: "var(--size-14)",
    flex: '1 1',
    color: 'white',
    margin: 0,
  }),
  // option: (base) => ({ ...base, lineHeight: '20px', alignSelf: 'center' })
  option: (styles) => {
    return {
      ...styles,
      // margin: "4px 0 4px 0px",
      fontFamily: "var(--font-family-nunito)",
      fontSize: "var(--size-14)",
      opacity: 1,
      backgroundColor: "#fff",
      fontWeight: '400',
      lineHeight: '18px',
      letterSpacing: '-0.01em',
      zIndex: 20,
      fontStretch: "normal",
      fontStyle: "normal",
      textAlign: "left",
      color: "#444444",
      cursor: "pointer",
      ":hover": {
        backgroundColor: "var(--hover-green)",
        opacity: 1,
      },
    };
  },
  placeholder: (defaultStyles) => {
    return {
      ...defaultStyles,
      fontFamily: "var(--font-family-nunito)",
      fontSize: "var(--size-14)",
      opacity: '0.8',
    };
  },
};
