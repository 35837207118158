import * as React from "react";

const SvgDashboard = (props) => (
  <svg
    width={18}
    height={18}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      className="fill"
      d="M10.875 4.5h1.75v8.25h-1.75V4.5Zm3.5-2.25h1.75v10.5h-1.75V2.25Zm-7 4.5h1.75v6h-1.75v-6ZM3 14.25h14v1.5H3v-1.5ZM3.875 9h1.75v3.75h-1.75V9Z"
      fill={props.iconColor}
      fillOpacity={0.6}
    />
  </svg>
);

export default SvgDashboard;
