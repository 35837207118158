import * as React from "react";

const SvgUsers = (props) => (
  <svg
    width={18}
    height={18}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      className="fill"
      d="M11.066 7.5c.724 0 1.312.588 1.312 1.313v2.437h-.003v.188a.562.562 0 1 1-1.125 0V9.75h.003v-.938a.188.188 0 0 0-.188-.187H6.94a.187.187 0 0 0-.188.188v2.437H6.75v.188a.562.562 0 1 1-1.125 0V9.75h.002v-.938c0-.724.587-1.312 1.312-1.312h4.127ZM15.375 8.813v2.624a.562.562 0 1 0 1.125 0V8.813A1.313 1.313 0 0 0 15.187 7.5h-2.53c.257.311.425.699.463 1.125h2.068a.188.188 0 0 1 .187.188ZM1.5 11.438a.562.562 0 1 0 1.125 0V8.812a.187.187 0 0 1 .188-.187h2.072c.037-.413.199-.805.463-1.125H2.813A1.313 1.313 0 0 0 1.5 8.813v2.624ZM9 2.25a2.25 2.25 0 1 1 0 4.5 2.25 2.25 0 0 1 0-4.5Zm0 1.125a1.125 1.125 0 1 0 0 2.25 1.125 1.125 0 0 0 0-2.25ZM13.875 3a1.875 1.875 0 1 1 0 3.75 1.875 1.875 0 0 1 0-3.75Zm0 1.125a.75.75 0 1 0 0 1.5.75.75 0 0 0 0-1.5ZM4.125 3a1.875 1.875 0 1 1 0 3.75 1.875 1.875 0 0 1 0-3.75Zm0 1.125a.75.75 0 1 0 0 1.5.75.75 0 0 0 0-1.5ZM2.063 12.75a.563.563 0 0 0-.563.563v.374A2.812 2.812 0 0 0 4.313 16.5h9.375a2.812 2.812 0 0 0 2.812-2.813v-.374a.562.562 0 0 0-.563-.563H2.063Zm2.25 2.625a1.688 1.688 0 0 1-1.677-1.5h12.728a1.688 1.688 0 0 1-1.677 1.5H4.313Z"
      fill="#fff"
      fillOpacity={0.6}
    />
  </svg>
);

export default SvgUsers;
