import _ from 'lodash';
import React from 'react';
import SpaceIO from '../../../../js/services/SpaceIO';
import Loader from '../../../ui/loader';
import './header.css';
import '../../../../css/freespace-web-app.css';
import Feature from '../../feature';
import FreespaceappService from '../../../../js/services/Freespaceapp-service';
import MenuService from '../../../../js/services/menu-service';
import { Container } from '../../../ui/layout';
import CacheService from '../../../../js/services/cache-service';
import CommonModal from '../../Modal';
import IconComponents from '../../../svg-icons/IconIndex';
const { moment } = window;
import Dropdown from '../../../ui/dropdown/dropdown';
import Select from 'react-select';
import LocationSelect from '../../shift-management-modules/common/components/shift-location-select-styles';

const groupStyles = {
  color: 'white',
  fontWeight: 700,
  background: `#ded9d9`,
  padding: '0px 0px',
  fontSize: '16px',
  lineHeight: '30px',
  inlineHeight: '30px',
  display: 'flex'
};

const GroupHeading = props => (
  <div style={groupStyles}>
    <components.GroupHeading {...props} />
  </div>
);

let MenuIconMv = IconComponents['menuIconMv'];


class Header extends React.Component {

  state = {
    loading: true,
    thresDate: null,
    menu: [],
    // thresDate: null,
    newNotification: [],
    previousNotification: [],
    SpaceAmenityList: [],
    query: "",
    accountInfo: null,
    currentPageMenu: { name: "", icon: "" },
    showPassword: true,
    accountPopup: false,
    locationOpts: [
      {
        label: 'Mumbai-Kamala-Mills-6th Flr',
        value: 1
      }
    ]
  };

  getLanguageFile = async () => {
    const userInfo = await FreespaceappService.MyDetail();
    const language = userInfo.data && userInfo.data.me && userInfo.data.me.langPreferences ? userInfo.data.me.langPreferences : 'en';
    const languageFile = await SpaceIO.api.authGetFreespaceappNoGraphQL(`translation?language=${language}`);
    const savableFile = languageFile ? JSON.stringify(languageFile) : null;
    CacheService.set('language-file', savableFile);
    return languageFile;
  };

  componentDidMount = async () => {
    let thresDatePromise = await FreespaceappService.getThresDate();
    Promise.all([thresDatePromise]).then(
      async ([thresDate]) => {
        console.log(thresDate);
        this.setState({ thresDate: thresDate.data.anonymizationPeriod });
        this.handlegetNotification();

        const lngFile = CacheService.get('language-file');
        const languageFile = lngFile ? JSON.parse(lngFile) : await this.getLanguageFile();

        await MenuService.getMenu().then((menu) => {
          this.setState({
            menu
          });
        });
        await SpaceIO.waitForInit();
        this.getMyStatus();

        this.setState({
          accountInfo: SpaceIO.user,
          languageFile,
        });
        let allsso = await SpaceIO.api.authGetJSON('admin/ssodetails');
        let ssoAccountList = allsso.filter(v => v.ssotype !== "DEFAULT").map(v => ({ id: v.id, ssotype: v.ssotype })).map(v => v.id);
        if (ssoAccountList.includes(this.state.accountInfo.account.id)) {
          this.setState({
            showPassword: false
          });
        }
      });
  };



  handleclose = () => {
    this.handlegetNotification();
  }


  handlegetNotification = () => {
    FreespaceappService.notificationHistory().then(res => {
      if (_.isEmpty(res) || res == null || res.data.notificationHistory == null) {
        this.setState({ loading: false, newNotification: [], previousNotification: [] });
      } else {
        let newNotification = res.data.notificationHistory.filter(notification => notification.seenAt == null && moment(notification.publishedAt).isAfter(moment.unix(this.state.thresDate)));
        let previousNotification = res.data.notificationHistory.filter(notification => notification.seenAt != null && moment(notification.publishedAt).isAfter(moment.unix(this.state.thresDate)));
        this.setState({ loading: false, newNotification: newNotification, previousNotification: previousNotification });
      }
    }).catch(() => {
      this.setState({ loading: false, newNotification: [], previousNotification: [] });
    });

  }


  handleNotificationSeen = (NotificationIds) => {
    FreespaceappService.notificationSeen(NotificationIds).catch(err => {
      console.log("err", err);
    });
  }
  accounthandleClose = () => {
    this.setState({ accountPopup: false });
    this.getMyStatus();
  };
  handlechangeGhostModeStatus = () => {  //  For the ghost mode
    this.setState({ isGhostModeEnabled: !this.state.isGhostModeEnabled || false });
    this.saveGhostModeStatus(!this.state.isGhostModeEnabled);
  }


  saveGhostModeStatus = (isGhostModeEnabled) => {
    FreespaceappService.setUserGhostModeStatus(isGhostModeEnabled)
      .then(res => {
        if (res.data && !res.errors) {
          this.setState({
            showGhostModePopup: false,
            originalGhostModeState: isGhostModeEnabled,
          });
          // toastr.success(this.state.languageFile["account.update.success"] || "Successfully updated your details.");
          this.handleClose();
        }
        // else {
        //   toastr.error(this.state.languageFile["account.error"] || "Could not update your details. Please try again later.");
        // }
      })
      .catch(err => console.debug("Caught error ", err));
  };

  handleClose = () => {
    this.setState({ showGhostModePopup: false });
    this.getMyStatus();
  };

  getMyStatus = () => {
    FreespaceappService.getUserGhostModeStatus()
      .then(res => {
        const isGhostModeEnabled = res.data.userVisibility && res.data.userVisibility.enable ? res.data.userVisibility.enable : false;
        this.setState({
          isGhostModeEnabled: isGhostModeEnabled,
          originalGhostModeState: isGhostModeEnabled,
        });
      })
      .catch(err => console.debug("Caught error ", err));
  }

  handleNotificationList = () => {
    this.setState({ loading: true });
    this.handlegetNotification();
    let { newNotification } = this.state;
    let NotificationIds = [];
    NotificationIds = newNotification.map(obj => obj.id);
    if (!_.isEmpty(newNotification)) {
      this.handleNotificationSeen(NotificationIds);
    }

  }

  RedirecttoPage = (data) => {
    let currenturl = window.location.href;
    let hosturl = window.location.href;
    let action = "";
    if (data !== null) {
      action = data.action;
    } else {
      action = "MYCIRCLENOTIFICATION-DESK";
    }
    switch (action) {
      case "MYCIRCLENOTIFICATION-REQUESTED":
        hosturl = currenturl.substr(0, currenturl.lastIndexOf("/"));
        window.location.href = `${hosturl}/mycircle.html#requestpending`;
        break;
      case "MYCIRCLENOTIFICATION-ACCEPTED":
        hosturl = currenturl.substr(0, currenturl.lastIndexOf("/"));
        window.location.href = `${hosturl}/mycircle.html#mycircle`;
        break;

      case "MYCIRCLENOTIFICATION-DESK":
        hosturl = currenturl.substr(0, currenturl.lastIndexOf("/"));
        window.location.href = `${hosturl}/todayschedule.html`;
        break;
      default:
        hosturl = currenturl.substr(0, currenturl.lastIndexOf("/"));
        window.location.href = `${hosturl}/todayschedule.html`;
        break;
    }
  }



  getNotificationTime = (publishedAt) => {
    let currentdate = moment(new Date());
    let check = moment(publishedAt).isSame(currentdate, 'day');
    let timedatamessage;
    let timestampdiffdays = currentdate.diff(moment(publishedAt), 'days');
    let timestampdiffhours = currentdate.diff(moment(publishedAt), 'hours');
    let timestampdiffminutes = currentdate.diff(moment(publishedAt), 'minutes');
    let timestampdiffseconds = currentdate.diff(moment(publishedAt), 'seconds');

    if (check) {

      if (timestampdiffdays < 1) {
        timedatamessage = `${this.state.languageFile ? this.state.languageFile["general.today"] : 'Today'}, ${timestampdiffhours} ${this.state.languageFile["web.hours.ago"] || 'hours ago'}`;
      }
      if (timestampdiffhours < 1) {
        timedatamessage = `${this.state.languageFile ? this.state.languageFile["general.today"] : 'Today'}, ${timestampdiffminutes} ${this.state.languageFile["web.minutes.ago"] || 'minutes ago'}`;
      }
      if (timestampdiffminutes < 1) {
        timedatamessage = `${this.state.languageFile ? this.state.languageFile["general.today"] : 'Today'}, ${timestampdiffseconds} ${this.state.languageFile["web.seconds.ago"] || 'seconds ago'}`;
      }
      return timedatamessage;
    } else {
      timedatamessage = moment(publishedAt).format('D MMMM YYYY');
      return timedatamessage;
    }

  }

  stateChange(e) {
    e.preventDefault();
    this.setState({ accountPopup: true });
  }


  renderNavBar() {

    let { newNotification, previousNotification } = this.state;

    if (!this.state.accountInfo) {
      return '';
    }

    const accountInfo = this.state.accountInfo.account;
    const showPassword = this.state.showPassword;
    return <ul className="nav navbar-nav">
      <Feature token="ACCOUNT.SEARCH" showMessage={false}>
        <li>
          <form className="search-form" onSubmit={(e) => {
            e.preventDefault();
            window.location.href = 'diagnostics.html?q=' + this.state.query;
          }}>
            <div className={`form-group has-feedback ${this.state.expand}`}>
              <label className="sr-only">Search</label>
              <input type="text" autoComplete="off" value={this.state.query}
                onChange={(event) =>
                  this.setState({ query: event.target.value })
                }
                onClick={() =>
                  this.setState({ expand: "expand" })
                }
                onBlur={() => function () {
                  if (this.state.query.length <= 0) {
                    this.setState({ expand: "expand" });
                  } else {
                    this.setState({ expand: "noexpand" });
                  }
                }
                }
                onPaste={() => this.setState({ expand: "expand" })}
                className="form-control" name="search" id="search" placeholder=" Search by device id" />
              <span className="glyphicon glyphicon-search form-control-feedback"></span>
            </div>
          </form>
        </li>
      </Feature>
      <Feature token="USER_APP.NOTIFICATIONS.IN_APP" showMessage={false}>
        <li className="dropdown user" id="company-panel">
          <a href="#" onClick={this.handleNotificationList} className="dropdown-toggle not-bell" data-toggle="dropdown" title={"Notifications"}>
            <img src="images/new-bell-regular.svg" className="notification-image image rounded-full border border-gray-100 shadow-sm" alt={accountInfo.name} />
            {newNotification.length ? <div className="bell-newbadge"></div> : null}
          </a>

          <ul className="dropdown-menu notification-box-container" >
            {this.state.loading ? <Container><Loader size="50" color='gray' icon="fa-refresh fa-spin" background='white' /></Container> :
              <div>
                {newNotification.length == 0 && previousNotification.length == 0 ?
                  <div className="notification-container custom-scroll">
                    <div className="NoDataMessage">No New Notifications here</div>
                  </div>
                  :
                  <div className="notification-container custom-scroll">
                    {/* <div>
                      <img src="images/close.svg" className="pull-right" style={{ cursor: "pointer" }} onClick={this.handleclose} />
                    </div> */}
                    <li className={`Notification-New ${newNotification.length == 0 ? 'hide' : ''}`} style={{ height: 'auto' }}>
                      {/* <div className="notification-header">{this.state.languageFile && this.state.languageFile["notification.new"] ? this.state.languageFile["notification.new"].replace("${number}", newNotification.length) : 'New Notification'}</div> */}
                      <div>
                        {this.state.newNotification.map((notification) => (
                          <div className="notification" key={notification.id}>
                            {/* <img className="header-element-profile" src={notification.icon} /> */}
                            <div className="notification-item">
                              <div className='notification-date'>{this.getNotificationTime(notification.publishedAt)}</div>
                              <div className="notification-content" onClick={() => this.RedirecttoPage(notification.data)}>{notification.content}</div>
                            </div>
                          </div>
                        ))}
                      </div>


                    </li>
                    <li className="Notification-Old" style={{ height: 'auto' }}>
                      {/* <div className="notification-header">{this.state.languageFile && this.state.languageFile["notification.previous"] ? this.state.languageFile["notification.previous"] : 'Previous Notifications'}</div> */}
                      <div>
                        {this.state.previousNotification.map((notification) => (
                          <div className="notification" key={notification.id}>
                            {/* <img className="header-element-profile" src={notification.icon} /> */}
                            <div className="notification-item">
                              <div>{this.getNotificationTime(notification.publishedAt)}</div>
                              <div className="notification-content" onClick={() => this.RedirecttoPage(notification.data)}>{notification.content}</div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </li>
                  </div>
                }
              </div>
            }

          </ul>


        </li>

      </Feature>


      <li className="dropdown mob-scr user user-menu" id="company-panel">
        <a className="dropdown-toggle acc-anc" title={accountInfo.name}>
          <img src={accountInfo.logoUrl} className="acc-logo" alt={accountInfo.name} crossOrigin="anonymous" />
        </a>
        <ul className="dropdown-menu" style={{ width: 'auto' }}>
          {
            showPassword && (
              <li className="user-footer">
                <div className="pull-right">
                  <a href="password-change.html" className="btn btn-default btn-flat">Change Password</a>
                </div>
              </li>)
          }
        </ul>
      </li>
      {(window.innerWidth >= 878) ?
        <li className="tooltipBoundary" >
          <a href="" style={{ margin: '0px 8px 0px 5px' }} className="button" onClick={(e) => this.stateChange(e)}>
            <img style={{ borderRadius: "50%" }} src={`${this.state.accountInfo.profileImage}`} alt="img not loaded" width="40px" height="40px" ></img>

          </a>

        </li> :
        <li className="tooltipBoundary" >
          <a href="" className={`button notifi ${this.state.accountPopup ? 'account' : ''}`} onClick={(e) => this.stateChange(e)}>
            <img style={{ borderRadius: "50%" }} src={`${this.state.accountInfo.profileImage}`} alt="img not loaded" width="35px" height="35px" ></img>

          </a>

        </li>}
      <li className="navAddition" style={{ width: 30 }}></li>

    </ul>;
  }
  renderHeader() {
    let b;
    let treeiteration = (newItem) => {
      newItem.map((item) => {
        if (item.items) {
          treeiteration(item.items);
        } else if (!item.items) {
          if (item.url === this.getPageName()) {
            return b = item.name;
          }
        }
      });
    };
    this.state.menu.map((item) => {
      if (item.items) {
        treeiteration(item.items);
      } else if (!item.items) {
        if (item.url === this.getPageName()) {
          return b = item.name;
        }
      }
    });
    return <div className='Page-Name'>{b}</div>;
  }

  getPageName = () => {
    let url = window.location.href.split("/");
    return url.pop();

  };

  render() {
    const options = [
      { value: '6th floor', label: '6th Floor' }
    ]
    return (
      <React.Fragment>
        <header className="main-header">
          <div className='desktop main-header'>
            <nav className="navbar navbar-static-top" role="navigation">
              {this.renderHeader()}
              <div className="navbar-custom-menu navbar-layout">
                {this.renderNavBar()}
                {this.state.languageFile ? (
                  <CommonModal
                    handleClose={this.accounthandleClose}
                    checkin={this.saveGhostModeStatus}
                    type="accountPopUp"
                    show={this.state.accountPopup || false}
                    languageFile={this.state.languageFile}
                    ghostModeStatus={this.state.isGhostModeEnabled || false}
                    originalGhostModeState={this.state.originalGhostModeState || false}
                    changeToggle={this.handlechangeGhostModeStatus}
                    accountInfo={this.state}
                  />
                ) : ""
                }
              </div>
            </nav>
          </div>
          <div className="mobileView">
            <div className="container col-sm-12" style={{ height: '43px' }} >
              <a href="#" className="menuIcon" data-toggle="offcanvas" role="button" style={{ fontSize: '200%', padding: '0px 15px 0px 0px' }}>
                <MenuIconMv />
              </a>
              <a href="home.html" className="f-app visible-xs visible-sm">
                <span className="freespace-app">{'My Freespace App'}</span>
              </a>
              <div className="navbar-custom-menu navbar-layout">
                {this.renderNavBar()}
              </div>
            </div>

          </div>
          <section>

            <div></div>
          </section>

        </header >
        {/* {this.state.menu?<HeaderNavMenu />:''} */}
      </React.Fragment>);

  }
}

export default Header;
