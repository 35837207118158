import BaseService from './base-service';
import TokenService from "./token-service";
import SpaceIO from "./SpaceIO";
import RefreshTokenService from "./refresh-token-service";

const AuthTokenService = {
  async getKey() {
    let key = window.localStorage.getItem('spaceio-key');
    if (key) {
      this.__key = key;
      if(RefreshTokenService.getKey() && this.isAboutToExpired()) {
        await SpaceIO.api.getAuthTokenFromRefreshToken();
        return this.__key;
      }
      else return key;
    }
    return null;
  },
  async setKey(key) {
    this.__key = key;
    if (key) {
      await window.localStorage.setItem('spaceio-key', key);
    } else {
      window.localStorage.removeItem('spaceio-key');
    }
  },
  getClaim() {
    return TokenService.getClaim(this.__key);
  },
  isExpired() {
    return TokenService.isExpired(this.__key);
  },
  isAboutToExpired() {
    return TokenService.isAboutToExpired(this.__key);
    /*if (this.isExpired())
      return true;
    const claim = this.getClaim();
    if(!claim) return true;
    const newAuthTokenBufferTimeInSeconds = 60 * 5;
    const tokenIssueTime = new Date(claim.iat * 1000).getTime();
    const currentTime = new Date().getTime();
    const diffMs = Math.abs(currentTime - tokenIssueTime);
    const diffSeconds = Math.floor(diffMs/1000);
    return  diffSeconds >= newAuthTokenBufferTimeInSeconds;*/
  },
  isLoggedOut() {
    return !this.key;
  }
};

BaseService.decorate(AuthTokenService, TokenService);
export default AuthTokenService;
