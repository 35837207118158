import React from "react";
import ConfigService from "../../../../../js/services/config-service";


class SideMenuHeading extends React.Component {
  render() {
    if (!ConfigService.enableBetaFeatures && this.props.item.beta) {
      return '';
    }
    let target = this.props.item.target || '';
    return (
      <li >
        <a className="side-menu-heading" href={this.props.item.url} target={target}>
          <span>{this.props.item.name.toUpperCase()}</span>
          {this.props.item.status? <span className="livebadge">{this.props.item.status}</span>:null}
        </a>
      </li>
    );
  }
}

export default SideMenuHeading;