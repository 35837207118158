import * as React from "react";

const SvgMap = (props) => (
  <svg
    width={15}
    height={15}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      className="stroke"
      d="M12 7.5h2.5M10 .5h4.5v14H.5V.5h4l3 2 2.5-2Zm-3.5 14v-7 7ZM4 7.5h5-5Z"
      stroke={props.iconColor}
      strokeOpacity={0.6}
    />
  </svg>
);

export default SvgMap;
