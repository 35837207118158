import * as React from "react";

const SvgChild = (props) => (
  <svg
    width={8}
    height={8}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      className="fill"
      d="M4 7.333A3.333 3.333 0 1 1 4 .667a3.333 3.333 0 0 1 0 6.666Zm0-6a2.667 2.667 0 1 0 0 5.334 2.667 2.667 0 0 0 0-5.334Z"
      fill="#fff"
      fillOpacity={0.6}
    />
  </svg>
);

export default SvgChild;
