import * as React from "react";

const SvgMenuIconMv = (props) => (
  <svg
    width={18}
    height={18}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g opacity={0.8} fill="#444">
      <path
        className="fill"
        d="M2 4.5c0-.133.079-.26.22-.354A.971.971 0 0 1 2.75 4h13.5a.97.97 0 0 1 .53.146c.141.094.22.221.22.354 0 .133-.079.26-.22.354a.97.97 0 0 1-.53.146H2.75a.971.971 0 0 1-.53-.146C2.079 4.76 2 4.633 2 4.5ZM2 8.5c0-.133.079-.26.22-.354A.971.971 0 0 1 2.75 8h13.5a.97.97 0 0 1 .53.146c.141.094.22.221.22.354 0 .133-.079.26-.22.354a.97.97 0 0 1-.53.146H2.75a.971.971 0 0 1-.53-.146C2.079 8.76 2 8.633 2 8.5ZM2.75 12a.972.972 0 0 0-.53.146c-.141.094-.22.221-.22.354 0 .133.079.26.22.354.14.093.331.146.53.146h13.5c.199 0 .39-.053.53-.146.141-.094.22-.221.22-.354 0-.133-.079-.26-.22-.354a.971.971 0 0 0-.53-.146H2.75Z" />
    </g>
  </svg>
);

export default SvgMenuIconMv;
