import * as React from "react";

const SvgMvhome = (props) => (
  <svg
    width={18}
    height={18}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      className="stroke"
      d="M16.5 16.06v-5.988c0-.417-.083-.83-.245-1.212a3.062 3.062 0 0 0-.695-1.013l-5.53-5.332A1.484 1.484 0 0 0 9 2.097c-.383 0-.752.15-1.03.418L2.44 7.847a3.062 3.062 0 0 0-.695 1.013 3.114 3.114 0 0 0-.245 1.212v5.987c0 .406.158.796.44 1.082.28.287.662.449 1.06.449h12c.398 0 .78-.162 1.06-.448.282-.287.44-.677.44-1.083Z"
      stroke="#444"
      strokeLinecap="round"
      strokeLinejoin="round"
      opacity={0.7}
    />
  </svg>
);

export default SvgMvhome;
