import BaseService from "./base-service";
import configService from './config-service';

const {$} = window;

const MultiRegionService = {
  getAccountDataCDN(domain) {
    return new Promise((resolve) => {

      let posting = $.ajax({
        type: "GET",
        url: `${configService.discoveryApi}/tenant/${domain}`,
        contentType: "application/json",
        headers: {}
      });

      posting.done(response=>resolve(response.data));

      posting.fail(() => {
        resolve(null);
      });
    });
  }
};

BaseService.decorate(MultiRegionService);
export default MultiRegionService;
