import jwt_decode from "jwt-decode";
import BaseService from './base-service';
import configService from './config-service';

const {NEW_AUTH_TOKEN_BUFFER_TIME_IN_SECONDS} = configService;

const TokenService = {
  getClaim(token) {
    if(token && !token.includes("/")) return jwt_decode(token);
    else return null;
  },
  isExpired(token) {
    const claim = this.getClaim(token);
    if(!claim) return true;
    const tokenExpiryTime = new Date(claim.exp * 1000).getTime();
    const currentTimeInMills = new Date().getTime();
    return currentTimeInMills > tokenExpiryTime;
  },
  isAboutToExpired(token) {
    if (this.isExpired(token))
      return true;
    const claim = this.getClaim(token);
    if(!claim) return true;
    const newAuthTokenBufferTimeInSeconds = Number(NEW_AUTH_TOKEN_BUFFER_TIME_IN_SECONDS);
    const tokenExpiryTime = new Date(claim.exp * 1000).getTime();
    const currentTime = new Date().getTime();
    const diffMs = Math.abs(tokenExpiryTime - currentTime);
    const diffSeconds = Math.floor(diffMs/1000);
    return  diffSeconds <= newAuthTokenBufferTimeInSeconds;
  },
  async __init() {
    try {
      console.log("token service initiated");
    } catch (err) {
      console.error('error initialising', err);
      throw err;
    }
  }
};

BaseService.decorate(TokenService);
export default TokenService;
