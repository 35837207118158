const { default: configService } = require("./config-service");
const { LOCALSTORAGE_KEYS } = require("./freespace_constants");

const CacheService = {
  get(key, defaultValue = null) {
    const storedValue = localStorage.getItem(key);
    if (storedValue !== null) {
      return JSON.parse(storedValue);
    }
    if (defaultValue !== null) {
      this.set(key, defaultValue);
      return defaultValue;
    }
    return null;
  },
  getNonJSON(key, defaultValue = null) {
    const storedValue = localStorage.getItem(key);
    if (storedValue !== null) {
      return storedValue;
    }
    if (defaultValue !== null) {
      this.set(key, defaultValue);
      return defaultValue;
    }
    return null;
  },
  setNonJSON(key, value) {
    localStorage.setItem(key, value);
  },
  set(key, value) {
    localStorage.setItem(key, JSON.stringify(value));
  },
  containsKey(key) {
    return localStorage.getItem(key) !== null;
  },
  getCacheAsArray() {
    return Object.entries(localStorage);
  },
  getAllCacheKeys() {
    return Object.keys(localStorage);
  },
  removeKeys(keys = []) {
    keys.forEach(k => localStorage.removeItem(k));
  },
  validateNewRelease() {
    const versionId = localStorage.getItem(LOCALSTORAGE_KEYS.APP_VERSION_KEY);
    if (!versionId || (versionId && versionId != configService.VERSION)) {
      let allKeys = CacheService.getAllCacheKeys();
      const IGNORED_KEYS = [LOCALSTORAGE_KEYS.SPACEIO_LAST_ACTION, LOCALSTORAGE_KEYS.AUTH_KEY_NAME, LOCALSTORAGE_KEYS.SPACEIO_USER, LOCALSTORAGE_KEYS.SPACEIO_IDLE_TIMEOUT_STATUS, LOCALSTORAGE_KEYS.SPACEIO_LAST_ACTION, LOCALSTORAGE_KEYS.SPACEIO_REFRESH_TOKEN];
      allKeys = allKeys.filter(key => !IGNORED_KEYS.includes(key));
      CacheService.removeKeys(allKeys);
      CacheService.set(LOCALSTORAGE_KEYS.APP_VERSION_KEY, configService.VERSION);
    }
  },
};


module.exports = CacheService;
