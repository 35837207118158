import React from "react";
import SideMenuItem from './sideMenuItem';
import IconComponents from "../../../../svg-icons/IconIndex";

class SideMenuTree extends React.Component {
  render() {
    let IconComponent = IconComponents['building']; // this.props.item.icon
    let IconComponen = IconComponents['dropdown'];
    let items = this.props.item.items.map(function (item) {
      if ('items' in item) {
        return (
          <SideMenuTree item={item} key={item.name} />
        );
      } else {
        return (
          <SideMenuItem item={item} icon={item.icon} key={item.url} />
        );
      }
    });
    return (
      <li>
        <a id="tree-head-main" href="#">
          {this.props.item.icon.indexOf(".svg" || ".png") !== -1 ?
            <img className="tree-head-img" src={`images/${this.props.item.icon}`}></img>
            // : <img id="iconimg" className="fa fa-" src={`images/${this.props.item.icon}.svg`}/>}
            : <IconComponent iconColor={'#fff'} className="fa fa-" />}
          <span id="thi" className="tree-head-ind">{this.props.item.name}</span>
          <small className="tree-head-arrow" ><IconComponen /></small>
        </a>
        <ul className='treeview-menu'>
          {items}
        </ul>
      </li>
    );
  }
}


export default SideMenuTree;
