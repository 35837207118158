import * as React from "react";

const SvgDropdown = (props) => (

  <svg width={16} height={16} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path className="fill" d="M25.3334 13.56C25.334 13.7592 25.2901 13.956 25.2047 14.136C25.1193 14.3159 24.9947 14.4745 24.84 14.6L16.84 21.04C16.6015 21.2361 16.3022 21.3433 15.9934 21.3433C15.6845 21.3433 15.3853 21.2361 15.1467 21.04L7.14671 14.3733C6.87442 14.147 6.70319 13.8218 6.67068 13.4692C6.63817 13.1166 6.74706 12.7656 6.97337 12.4933C7.19969 12.221 7.52491 12.0498 7.87748 12.0173C8.23004 11.9848 8.58108 12.0937 8.85337 12.32L16 18.28L23.1467 12.52C23.3424 12.3569 23.5807 12.2534 23.8335 12.2215C24.0862 12.1897 24.3428 12.2309 24.5728 12.3403C24.8028 12.4497 24.9967 12.6227 25.1315 12.8389C25.2663 13.055 25.3363 13.3053 25.3334 13.56Z" fill={'rgba(255, 255, 255, 0.6)'} />
  </svg>
);

export default SvgDropdown;
