import * as React from "react";

const SvgManageusers = (props) => (
  <svg
    width={18}
    height={18}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      className="fill"
      d="M9 4.5a2.812 2.812 0 1 0 0 5.625A2.812 2.812 0 0 0 9 4.5ZM9 9a1.687 1.687 0 1 1 0-3.375A1.687 1.687 0 0 1 9 9Z"
      fill="#fff"
      fillOpacity={0.6}
    />
    <path
      className="fill"
      d="M9 1.125A7.875 7.875 0 1 0 16.875 9 7.884 7.884 0 0 0 9 1.125ZM5.625 14.837v-.774a1.689 1.689 0 0 1 1.688-1.688h3.375a1.69 1.69 0 0 1 1.687 1.688v.774a6.694 6.694 0 0 1-6.75 0Zm7.87-.816a2.814 2.814 0 0 0-2.807-2.771H7.312a2.814 2.814 0 0 0-2.808 2.77 6.75 6.75 0 1 1 8.992 0Z"
      fill="#fff"
      fillOpacity={0.6}
    />
  </svg>
);

export default SvgManageusers;
