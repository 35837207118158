import React from 'react';
import './footer.css';

class Footer extends React.Component {
  render() {
    return (<footer className="main-footer">
      <div className='right-content'>
        Copyright &copy; {new Date().getFullYear()} <a className="anchor" href="http://www.afreespace.com/" target='_blank' rel="noopener noreferrer">Freespace</a>.
      </div>
    </footer>);
  }
}

export default Footer;
