import * as React from "react";

const SvgLocation = (props) => (
  <svg width="14" height="14" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg" style={{...props}}>
    <path d="M8 0.666626C9.5913 0.666626 11.1174 1.29877 12.2426 2.42399C13.3679 3.5492 14 5.07533 14 6.66663C14 9.15063 12.1067 11.7533 8.4 14.5333C8.2846 14.6198 8.14425 14.6666 8 14.6666C7.85575 14.6666 7.7154 14.6198 7.6 14.5333C3.89333 11.7533 2 9.15063 2 6.66663C2 5.07533 2.63214 3.5492 3.75736 2.42399C4.88258 1.29877 6.4087 0.666626 8 0.666626ZM8 1.99996C6.76232 1.99996 5.57534 2.49162 4.70017 3.36679C3.825 4.24196 3.33333 5.42895 3.33333 6.66663C3.33333 8.47329 4.74533 10.5573 7.632 12.8706L8 13.1613L8.368 12.8706C11.2547 10.5573 12.6667 8.47329 12.6667 6.66663C12.6667 5.42895 12.175 4.24196 11.2998 3.36679C10.4247 2.49162 9.23768 1.99996 8 1.99996ZM8 5.33329C8.35362 5.33329 8.69276 5.47377 8.94281 5.72382C9.19286 5.97387 9.33333 6.313 9.33333 6.66663C9.33333 7.02025 9.19286 7.35939 8.94281 7.60944C8.69276 7.85948 8.35362 7.99996 8 7.99996C7.64638 7.99996 7.30724 7.85948 7.05719 7.60944C6.80714 7.35939 6.66667 
    7.02025 6.66667 6.66663C6.66667 6.313 6.80714 5.97387 7.05719 5.72382C7.30724 5.47377 7.64638 5.33329 8 5.33329Z" fill={props.color||"#2E375B"} fillOpacity="0.8" />
  </svg>
);

export default SvgLocation;
