import * as React from "react";

const SvgFafaHandPaperO = (props) => (
  <svg
    width={18}
    height={18}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      className="fill"
      d="M15.73 4.285a1.556 1.556 0 0 0-2.23-1.41 1.595 1.595 0 0 0-2.33-1.32 1.645 1.645 0 0 0-3.21-.38 1.615 1.615 0 0 0-1.986.254A1.635 1.635 0 0 0 5.5 2.59v6.33a90.38 90.38 0 0 1-1.36-1.725 1.595 1.595 0 0 0-1.36-.745 1.685 1.685 0 0 0-1.735 1.74C1.09 9.09 2.83 12.27 4 14c1.77 2.62 3.46 3 3.535 3h5.26a.46.46 0 0 0 .275-.095 6.564 6.564 0 0 0 1.875-3.065c.5-1.545.765-3.765.79-6.78l-.005-2.775Zm-1.64 9.275a6.23 6.23 0 0 1-1.47 2.54H7.665c-.235-.07-1.535-.55-2.935-2.625-1.4-2.075-2.76-4.84-2.785-5.33a.75.75 0 0 1 .225-.565.76.76 0 0 1 .57-.23.715.715 0 0 1 .66.355c.145.215 1.18 1.5 1.785 2.265L6.4 9.15V2.59a.74.74 0 1 1 1.475 0v5.57h.9v-6.5a.755.755 0 0 1 1.5 0v6.565h.9V3a.714.714 0 0 1 1.239-.544A.715.715 0 0 1 12.6 3v5.72h.9V4.27a.665.665 0 0 1 1.325 0v2.775c-.015 2.955-.255 5.06-.735 6.515Z"
      fill="#fff"
      fillOpacity={0.6}
    />
  </svg>
);

export default SvgFafaHandPaperO;
