import * as React from "react";

const SvgMvCalender = (props) => (
  <svg
    width={18}
    height={18}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g opacity={0.7}>
      <path
        className="stroke"
        d="M13.5 3h-9a3 3 0 0 0-3 3v7.5a3 3 0 0 0 3 3h9a3 3 0 0 0 3-3V6a3 3 0 0 0-3-3ZM6 1.5v3M12 1.5v3M1.5 7.5h15"
        stroke="#444"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="stroke"
        d="M9.917 10.374a.75.75 0 1 1-.834-1.248.75.75 0 0 1 .834 1.248ZM13.667 10.374a.75.75 0 1 1-.834-1.248.75.75 0 0 1 .834 1.248ZM9.917 14.374a.75.75 0 1 1-.834-1.248.75.75 0 0 1 .834 1.248ZM13.667 14.374a.75.75 0 1 1-.834-1.248.75.75 0 0 1 .834 1.248ZM5.167 10.374a.75.75 0 1 1-.834-1.248.75.75 0 0 1 .834 1.248ZM5.167 14.374a.75.75 0 1 1-.834-1.248.75.75 0 0 1 .834 1.248Z"
        fill="#444"
      />
    </g>
  </svg>
);

export default SvgMvCalender;
