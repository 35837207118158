import React from 'react';
import MenuService from '../../../../js/services/menu-service';
import SpaceIO from '../../../../js/services/SpaceIO';
import FreespaceappService from '../../../../js/services/Freespaceapp-service';
import CacheService from '../../../../js/services/cache-service';
import _ from 'lodash';
import '../../../../css/FormSideMenu.css';
import '../../../../css/freespace-web-app.css';
import SideMenuHeading from './sideMenuComponents/sideMenuHeading';
import SideMenuItem from './sideMenuComponents/sideMenuItem';
import SideMenuTree from './sideMenuComponents/sideMenuTree';
import './side-menu.css';
import Footer from '../Footer/footer';

const { $ } = window;

class SideMenu extends React.Component {
  state = {
    menu: [{
      name: "Loading...",
      icon: "fa fa-gear"
    }],
    showActive: false,
    user: null,
  }

  getLanguageFile = async () => {
    const userInfo = await FreespaceappService.MyDetail();
    const language = userInfo.data.me && userInfo.data.me.langPreferences ? userInfo.data.me.langPreferences : 'en';
    const languageFile = await SpaceIO.api.authGetFreespaceappNoGraphQL(`translation?language=${language}`);
    const savableFile = languageFile ? JSON.stringify(languageFile) : null;
    CacheService.set('language-file', savableFile);
    location.reload();
    return languageFile;
  };

  loadLanguage = async () => {
    const lngFile = CacheService.get('language-file');
    const languageFile = lngFile ? JSON.parse(lngFile) : await this.getLanguageFile();
    return languageFile;
  };
  componentDidMount = () => {

    this.loadLanguage()
      .then(languageFile => this.setState({ languageFile }))
      .catch(err => console.debug(err));
    MenuService.getMenu().then((menu) => {
      this.setState({
        menu,
      });
      //checked and reset container height @TODO: need to fix this, as not right place for this code.
      setTimeout(function () {
        if ($(".sidebar").outerHeight() > $(".content-wrapper").outerHeight() + $(".main-footer").outerHeight()) {
          const finalHi = $(".sidebar").outerHeight() - $(".main-footer").outerHeight();
          $(".content-wrapper, .right-side").css('min-height', finalHi);
        }
      }, 1000);
    });
    SpaceIO.waitForInit().then(() => {
      this.setState({ user: SpaceIO.user });
    });

  }

  handleClose(e) {
    if (e.currentTarget.className === e.target.className) {
      $("body").removeClass("sidebar-open");
    }
  }

  render() {
    return (
      <React.Fragment>
        <aside className="main-sideba" >
          <a href="home.html" className="logos">
            <div className="logos-lg">
              <img src="images/freespace-negative@2x.png" width="150" />
            </div>
          </a>

          <section className="sidebar">
            <div id="user-panel">
              <div id="side-menu-container">
                <ul className="sidebar-menu">
                  {
                    _.map(this.state.menu, (ite) => {
                      <SideMenuItem item={ite} key={ite.name} />;
                      return (<>
                        <SideMenuHeading item={ite} key={ite.name} />
                        {_.map(ite.items, (item) => {
                          if ('items' in item) {
                            return <SideMenuTree item={item} key={item.name} />;
                          } else {
                            return <SideMenuItem item={item} key={item.name} />;
                          }
                        })}
                      </>);
                    })
                  }
                </ul>
              </div>
              <span className='dummyFooter'></span>
              <Footer />
            </div>
          </section>
        </aside>
        <div className='main-mobileSMview modal' onClick={(e) => this.handleClose(e)}>
          <a href="#" className="menuIcon crosIcon" data-toggle="offcanvas" role="button">
            <img src="images/icons/Group.svg" alt="Cross" />
          </a>
          <div className='MobileSidemenu' >
            <a href="home.html" className="logos">
              <div className="logos-lg">
                <img src="images/freespace-negative@2x.png" width="150" />
              </div>
            </a>
            <section className="sidebar">
              <div id="user-panel">
                <div id="side-menu-container">
                  <ul className="sidebar-menu">
                    {
                      _.map(this.state.menu, (ite) => {
                        <SideMenuItem item={ite} key={ite.name} />;
                        return (<>
                          <SideMenuHeading item={ite} key={ite.name} />
                          {_.map(ite.items, (item) => {
                            if ('items' in item) {
                              return <SideMenuTree item={item} key={item.name} />;
                            } else {
                              return <SideMenuItem item={item} key={item.name} />;
                            }
                          })}
                        </>);
                      })
                    }
                  </ul>
                </div>
              </div>
            </section>
            <span className='dummyFooter'></span>
            <Footer />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default SideMenu;
