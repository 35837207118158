import * as React from "react";

const SvgAccountexchange = (props) => (
  <svg
    width={18}
    height={18}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      className="fill"
      d="M15.938 5.625a.562.562 0 0 1 .557.486l.005.076v6.375a2.438 2.438 0 0 1-2.3 2.434l-.137.004H4.546l.54.54a.562.562 0 0 1 .054.732l-.055.063a.563.563 0 0 1-.732.055l-.063-.055-1.5-1.5-.048-.054a1.705 1.705 0 0 1-.005-.008l.052.062a.565.565 0 0 1 .001-.795l1.5-1.5a.563.563 0 0 1 .85.732l-.055.063-.54.54h9.518a1.312 1.312 0 0 0 1.307-1.205l.005-.107V6.186a.563.563 0 0 1 .563-.562ZM13.646 1.61l.063.055 1.5 1.5c.02.019.037.04.053.06l-.052-.06a.56.56 0 0 1 .003.792l-.004.003-1.5 1.5a.563.563 0 0 1-.85-.732l.055-.063.539-.54H3.937A1.312 1.312 0 0 0 2.63 5.329l-.005.108v6.375a.562.562 0 0 1-1.12.077l-.005-.076V5.436a2.438 2.438 0 0 1 2.3-2.433L3.938 3h9.516l-.54-.54a.563.563 0 0 1-.054-.732l.055-.063a.562.562 0 0 1 .732-.055ZM9 6a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 1.125a1.875 1.875 0 1 0 0 3.75 1.875 1.875 0 0 0 0-3.75Z"
      fill="#fff"
      fillOpacity={0.6}
    />
  </svg>
);

export default SvgAccountexchange;
