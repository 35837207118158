import * as React from "react";

const SvgFile = (props) => (
  <svg
    width={18}
    height={18}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      className="stroke"
      d="M11.25 6.75H7.2a.45.45 0 0 0-.45.45V12m0 3.75h4.5-4.5Zm0 0V12v3.75Zm0 0H2.7a.45.45 0 0 1-.45-.45v-2.85A.45.45 0 0 1 2.7 12h4.05v3.75Zm4.5 0v-9 9Zm0 0h4.05a.45.45 0 0 0 .45-.45V2.7a.45.45 0 0 0-.45-.45h-3.6a.45.45 0 0 0-.45.45v13.05Z"
      stroke="#fff"
      strokeOpacity={0.6}
      strokeWidth={1.125}
    />
  </svg>
);

export default SvgFile;
