import React,{ useState } from 'react';

const SecondaryButton = (props) => {
  const { buttonPrimaryColor, buttonSecondaryColor, textSecondaryColor, textPrimaryColor, bg_color='white' } = props;
  const [ state,setState] = useState({hover:false});
  let style;
  switch (props.buttonType ? props.buttonType.toLowerCase() : props.buttonType) {
    case 'small': style = 'custom-button-small freespace-small-text';
      break;
    default: style = 'custom-button-large freespace-large-text';
  }

  return (
    <button
      id='secondaryBtn'
      style={{
        ...props,
        backgroundImage: state.hover? `linear-gradient(90deg, ${buttonPrimaryColor} 0%, ${buttonSecondaryColor} 100%)`: `linear-gradient(${bg_color}, ${bg_color}),linear-gradient(90deg, ${buttonPrimaryColor} 0%, ${buttonSecondaryColor} 100%)`,
        color: state.hover ? textSecondaryColor : textPrimaryColor
      }}
      className={`${style} freespace-border-gradient-btn border-radius-5 orange-color font-bold freespace-horizontal-center`}
      onClick={props.action}
      disabled={props.disabled}
      onMouseOver={() => setState({ hover: true })}
      onMouseLeave={() => setState({ hover: false })}
    >
      {props.text}
    </button>
  );
};

export default SecondaryButton;
