import EulaService from './eula-service';
import FeatureService from './feature-service';
import UserService from './user-service';
import SpaceIO from './SpaceIO';
import BaseService from './base-service';

const BootstrapService = {
  async __init() {
    console.log('BootstrapService init start');
    try {
      await EulaService.waitForAcceptance();
      console.log('BootstrapService init done');
    } catch (err) {
      console.error('error initialising', err);
      throw err;
    }
  }
};
BaseService.decorate(BootstrapService, SpaceIO, UserService, FeatureService);
export default BootstrapService;
