import * as React from "react";

const SvgManagecheckCircle = (props) => (
  <svg
    width={18}
    height={18}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      className="stroke"
      d="M7.313 1.125v2.25l-1.125.563L4.5 2.25 2.25 4.5l1.688 1.688-.563 1.125h-2.25v3.375h2.25l.563 1.124L2.25 13.5l2.25 2.25 1.688-1.688 1.125.563v2.25h3.375v-2.25l1.124-.563L13.5 15.75l2.25-2.25-1.688-1.688.563-1.124h2.25V7.312h-2.25l-.563-1.125L15.75 4.5 13.5 2.25l-1.688 1.688-1.124-.563v-2.25H7.312Z"
      stroke="#fff"
      strokeOpacity={0.6}
      strokeWidth={1.125}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      className="stroke"
      d="M9 11.25a2.25 2.25 0 1 0 0-4.5 2.25 2.25 0 0 0 0 4.5Z"
      stroke="#fff"
      strokeOpacity={0.6}
      strokeWidth={1.125}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgManagecheckCircle;
