import _ from 'lodash';
import React from 'react';
import App from '../components/containers/app';
import MenuService from './../js/services/menu-service';
import Loader from '../components/ui/loader';

class Index extends React.Component {
  state = {
    loading: true
  }

  render() {
    return this.state.loading ?
      <Loader container={true} /> :
      <button className="btn btn-default" onClick={() => this.goTo(this.state.url)} >Go to home</button>;
  }

  goTo(url = this.state.url) {
    let currenturl = window.location.href;
    let hosturl = window.location.href;
    hosturl = currenturl.substr(0, currenturl.lastIndexOf("/"));
    window.location.href = `${hosturl}/${url}`;

  }

  async componentDidMount() {
    const menuItems = await MenuService.getMenu();
    const pendingItems = [...menuItems];
    let homeItem = {
      url: "home.html",
      homeOrder: Number.POSITIVE_INFINITY,
    };
    while (pendingItems.length > 0) {
      const item = pendingItems.shift();
      if (item.items) {
        pendingItems.push(...item.items);
      }
      if (!_.isUndefined(item.homeOrder) && item.homeOrder < homeItem.homeOrder) {
        homeItem = item;
      }
    }
    this.goTo(homeItem.url);
    this.setState({ url: homeItem.url, loading: false });
  }
}
App(Index);
