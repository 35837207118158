

import React from 'react';

const RotaIcon = (props) => {
  return (
    <React.Fragment>
      <div style={{ marginRight: '6%' }}>
        <svg width="18" height="20" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g opacity="0.6">
            <path d="M15.3 4.95001C15.3 4.35328 15.0629 3.78098 14.6409 3.35902C14.219 2.93707 13.6467 2.70001 13.05 2.70001H4.94995C4.35321 2.70001 3.78092 2.93707 3.35896 3.35902C2.937 3.78098 2.69995 4.35328 2.69995 4.95001V13.05C2.69995 13.6467 2.937 14.219 3.35896 14.641C3.78092 15.063 4.35321 15.3 4.94995 15.3H8.63995C8.49277 15.0124 8.3743 14.7109 8.28625 14.4H4.94995C4.59191 14.4 4.24853 14.2578 3.99536 14.0046C3.74218 13.7514 3.59995 13.4081 3.59995 13.05V6.30001H14.4V8.28631C14.7141 8.37541 15.0147 8.49331 15.3 8.64001V4.95001ZM4.94995 3.60001H13.05C13.408 3.60001 13.7514 3.74224 14.0045 3.99542C14.2577 4.24859 14.4 4.59197 14.4 4.95001V5.40001H3.59995V4.95001C3.59995 4.59197 3.74218 4.24859 3.99536 3.99542C4.24853 3.74224 4.59191 3.60001 4.94995 3.60001Z" fill="white" />
            <path d="M13.05 17.1C14.1241 17.1 15.1543 16.6733 15.9138 15.9138C16.6733 15.1543 17.1 14.1241 17.1 13.05C17.1 11.9759 16.6733 10.9457 15.9138 10.1862C15.1543 9.4267 14.1241 9 13.05 9C11.9759 9 10.9457 9.4267 10.1862 10.1862C9.4267 10.9457 9 11.9759 9 13.05C9 14.1241 9.4267 15.1543 10.1862 15.9138C10.9457 16.6733 11.9759 17.1 13.05 17.1ZM12.6 11.25C12.6 11.1307 12.6474 11.0162 12.7318 10.9318C12.8162 10.8474 12.9307 10.8 13.05 10.8C13.1693 10.8 13.2838 10.8474 13.3682 10.9318C13.4526 11.0162 13.5 11.1307 13.5 11.25V12.6H14.4C14.5193 12.6 14.6338 12.6474 14.7182 12.7318C14.8026 12.8162 14.85 12.9307 14.85 13.05C14.85 13.1693 14.8026 13.2838 14.7182 13.3682C14.6338 13.4526 14.5193 13.5 14.4 13.5H13.05C12.9307 13.5 12.8162 13.4526 12.7318 13.3682C12.6474 13.2838 12.6 13.1693 12.6 13.05V11.25Z" fill="white" />
          </g>
        </svg>
      </div>
    </React.Fragment>
  );
};

export default RotaIcon;