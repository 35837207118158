import * as React from "react";

const SvgWebappuser = (props) => (
  <svg
    width={18}
    height={18}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      className="fill"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.5 14.75a1.25 1.25 0 1 0 0-2.5 1.25 1.25 0 0 0 0 2.5Zm0 1.25a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5ZM8.5 8.667c-.263 0-.5.165-.5.384v7.532c0 .11.053.217.146.295.094.078.221.122.354.122.133 0 .26-.044.354-.122A.385.385 0 0 0 9 16.583V9.051c0-.22-.237-.384-.5-.384Zm.5-3.75c0 .11-.053.216-.146.294a.555.555 0 0 1-.354.122.555.555 0 0 1-.354-.122A.385.385 0 0 1 8 4.917v-2.5c0-.11.053-.217.146-.295A.555.555 0 0 1 8.5 2c.133 0 .26.044.354.122A.385.385 0 0 1 9 2.417v2.5ZM13.5 17a.555.555 0 0 1-.354-.122.385.385 0 0 1-.146-.295v-2.949c0-.219.237-.384.5-.384s.5.165.5.384v2.95c0 .11-.053.216-.146.294A.555.555 0 0 1 13.5 17ZM13 9.5c0 .11.053.216.146.295a.555.555 0 0 0 .354.122c.133 0 .26-.044.354-.122A.385.385 0 0 0 14 9.5V2.417a.385.385 0 0 0-.146-.295A.555.555 0 0 0 13.5 2a.555.555 0 0 0-.354.122.385.385 0 0 0-.146.295V9.5Z"
      fill="#fff"
      fillOpacity={0.6}
    />
    <path
      className="fill"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.5 17a.555.555 0 0 1-.354-.122.385.385 0 0 1-.146-.295v-.865c0-.22.237-.385.5-.385s.5.165.5.384v.866c0 .11-.053.217-.146.295A.555.555 0 0 1 3.5 17ZM3 11.167c0 .11.053.216.146.294.094.078.221.122.354.122.133 0 .26-.044.354-.122A.385.385 0 0 0 4 11.167v-8.75a.385.385 0 0 0-.146-.295A.555.555 0 0 0 3.5 2a.555.555 0 0 0-.354.122.385.385 0 0 0-.146.295v8.75ZM8.5 8.75a1.25 1.25 0 1 0 0-2.5 1.25 1.25 0 0 0 0 2.5Zm0 1.25a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5ZM13.5 12.75a1.25 1.25 0 1 0 0-2.5 1.25 1.25 0 0 0 0 2.5Zm0 1.25a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z"
      fill="#fff"
      fillOpacity={0.6}
    />
  </svg>
);

export default SvgWebappuser;
