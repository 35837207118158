
import React from 'react';
import Skeleton from 'react-loading-skeleton';
class Loader extends React.Component {
  render() {
    if (this.props.container) {
      return <div className="box box-solid">
        <div className="box-body">
          <Loader />
        </div>
      </div>;
    }
    if (this.props.isContent) {
      return <div className="box box-solid">
        <div className="box-body">
          <label>{this.props.isContent}</label>
          <Skeleton />
        </div>
      </div>;
    }
    const {
      margin = 0,
      background = 'rgba(0,0,0,0.5)',
      color = 'white',
      icon = "fa-spin fa-gear",
      size = 35
    } = this.props;
    return (<div className="overlay loader" style={{ background, height: `${size}px`, margin: `${margin}px` }}>
      <i className={`fa ${icon}`} style={{ color, ...this.props.iconStyle }}></i>
    </div>);
  }
}

export default Loader;
