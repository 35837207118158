export default {
  baseUrl: "https://accountws-sm.qa.afreespace.com",
  positionBaseUrl: "https://position.afreespace.com",
  positionSocketBaseUrl: "https://position-wss.afreespace.com",
  fireLoadingUrl: "https://analytics.afreespace.com",
  enableBetaFeatures: "undefined" !== "false",
  authURL: "https://login-01.qa.afreespace.com",
  userAppUrl: "https://user-app-api-sm.qa.afreespace.com/graphql",
  formFinalOriginUrl: "https://cdn.afreespace.com",
  NEW_AUTH_TOKEN_BUFFER_TIME_IN_SECONDS: "undefined",
  VERSION: "3.1.2",
  discoveryApi: "https://discovery.afreespace.com",
  shiftManagementApi: "undefined"
};
