import * as React from "react";

const SvgCogs = (props) => (
  <svg
    width={18}
    height={18}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      className="fill"
      d="M14.625 7.875A1.125 1.125 0 0 0 15.75 6.75V3.375a1.125 1.125 0 0 0-1.125-1.125H11.25a1.125 1.125 0 0 0-1.125 1.125V6.75a1.125 1.125 0 0 0 1.125 1.125h1.125v2.306a2.812 2.812 0 0 0-2.194 2.194H7.875V11.25a1.125 1.125 0 0 0-1.125-1.125H5.625V7.819a2.813 2.813 0 1 0-1.125 0v2.306H3.375A1.125 1.125 0 0 0 2.25 11.25v3.375a1.125 1.125 0 0 0 1.125 1.125H6.75a1.125 1.125 0 0 0 1.125-1.125V13.5h2.306a2.813 2.813 0 1 0 3.319-3.319V7.875h1.125ZM3.375 5.062a1.687 1.687 0 1 1 3.375 0 1.687 1.687 0 0 1-3.375 0Zm3.375 9.563H3.375V11.25H6.75v3.375Zm7.875-1.688a1.687 1.687 0 1 1-3.375 0 1.687 1.687 0 0 1 3.375 0ZM11.25 3.376h3.375V6.75H11.25V3.375Z"
      fill={props.iconColor}
      fillOpacity={0.6}
    />
  </svg>
);

export default SvgCogs;
