import UserService from './user-service';
import _ from 'lodash';
import BaseService from './base-service';

const STORAGE_KEY = "EULA";


const onAcceptCallbacks = [];
const notifyAcceptance = () => {
  while (!_.isEmpty(onAcceptCallbacks)) {
    const callback = onAcceptCallbacks.shift();
    callback();
  }
};



const EulaService = {
  async waitForAcceptance() {
    await this.waitForInit();
    return new Promise(resolve => {
      this.getStatus().then(result => {
        if (result.accepted) {
          resolve();
        } else {
          onAcceptCallbacks.push(resolve);
        }
      });
    });
  },
  async getStatus() {
    await this.waitForInit();
    return this.getStatusPromise;
  },
  async __init() {
    this.getStatusPromise = new Promise(async resolve => {
      if (window.localStorage.getItem(STORAGE_KEY) || UserService.isLegacyUser() || UserService.isOfficeUser()) {
        resolve({ accepted: true });
        return;
      }
      try {
        const preference = await UserService.getPreference("EULA");
        if (preference.hide) {
          window.localStorage.setItem(STORAGE_KEY, new Date(preference.updatedAt).getTime());
        }
        resolve({ accepted: preference.hide, url: preference.value });
      } catch (e) {
        console.log('Cannot read EULA preference', e);
        resolve({ accepted: false, url: '#' });
      }
    });
    await this.getStatusPromise;
    console.info('eula service inited');
  },
  accept(doNotShowAgain) {
    if (doNotShowAgain) {
      UserService.savePreference("EULA", true);
    }
    window.localStorage.setItem(STORAGE_KEY, Date.now());
    notifyAcceptance();
  }
};
BaseService.decorate(EulaService, UserService);
export default EulaService;
