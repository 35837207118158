import React from "react";
import ConfigService from "../../../../../js/services/config-service";
import IconComponents from "../../../../svg-icons/IconIndex";

class SideMenuItem extends React.Component {
  render() {
    let IconComponent = IconComponents['building']; // this.props.item.icon
    if (!ConfigService.enableBetaFeatures && this.props.item.beta) {
      return '';
    }
    let target = this.props.item.target || '';
    return (
      <li>
        {this.props.icon ?
          <a className="tree-head" href={this.props.item.url} target={target}>
            <span className="tree-head-ind treeblock">{this.props.item.icon.indexOf(".svg" || ".png") !== -1 ?
              <img className="tree-head-img" src={`images/${this.props.item.icon}`}></img>
              : <IconComponent iconColor={'#fff'} className="fa fa-" /> } {this.props.item.name}</span>
          </a> :
          <a className="tree-head" href={this.props.item.url} target={target}>
            {this.props.item.icon.indexOf(".svg" || ".png") !== -1 ?
              <img className="tree-head-img" src={`images/${this.props.item.icon}`}></img>
              : <IconComponent iconColor={'#fff'} className="fa fa-" />}
            <span className="tree-head-ind">{this.props.item.name}</span>
          </a>
        }</li>);
  }
}

export default SideMenuItem;