import './polyfill-service';
import _ from 'lodash';
import configService from './config-service';
import BaseService from './base-service';
import AuthTokenService from "./auth-token-service";
import RefreshTokenService from "./refresh-token-service";
import CacheService from './cache-service';
import MultiRegionService from "./multi-region-service";

const { $ } = window;

function defferedAjax(options) {
  const defer = $.Deferred();
  SpaceIO.api.verifyRequest().then(async () => {
    SpaceIO.lastAction = new Date().getTime();
  }).then(() => SpaceIO.lastSuccessFullApiCall = true).then(SpaceIO.waitForInit()).then(() => defer.resolve()).catch(console.log);
  return $.when(defer).then(() => $.ajax(options));
}

const SpaceIO = {
  api: {
    isIdleTimeOut: (idleTimeout) => {
      const lastAction = new Date(SpaceIO.lastAction);
      const currentTime = new Date();
      const diffMs = (currentTime - lastAction);
      const diffMinutes = Math.round(((diffMs % 86400000) % 3600000) / 60000);
      return diffMinutes >= idleTimeout;
    },
    base: configService.baseUrl,
    positionBase: configService.positionBaseUrl,
    verifyRequest: async function () {
      if (this.verifyRequestPromise) {
        return this.verifyRequestPromise;
      } else {
        this.verifyRequestPromise = this.__internalVerifyRequest();
        await this.verifyRequestPromise;
        this.verifyRequestPromise = null;
      }
    },
    __internalVerifyRequest: async function () {
      const authToken = await AuthTokenService.getKey();
      if (authToken && RefreshTokenService.getKey() && AuthTokenService.isAboutToExpired()) {
        const idleTimeOut = SpaceIO.user.account.idleTimeoutInMinutes;
        if (SpaceIO.idleTimeoutStatus === "true" && idleTimeOut) {
          if (!SpaceIO.lastSuccessFullApiCall) SpaceIO.logout();
          else if (this.isIdleTimeOut(idleTimeOut)) {
            await SpaceIO.expireSession("inactivity");
          } else {
            return await this.getAuthTokenFromRefreshToken();
          }
        } else {
          return await this.getAuthTokenFromRefreshToken();
        }
      }
    },
    getAuthTokenFromRefreshToken: async function () {
      try {
        if (!RefreshTokenService.getKey()) throw new Error("Refresh token missing");
        else
          await new Promise((resolve, reject) => {
            const posting = $.ajax({
              url: configService.baseUrl + '/api/refresh-token',
              type: 'POST',
              headers: {
                'x-refresh-token': RefreshTokenService.getKey(),
              }
            });
            posting.done((user) => {
              SpaceIO.user = user;
              AuthTokenService.setKey(posting.getResponseHeader('X-Auth-Key'));
              RefreshTokenService.setKey(posting.getResponseHeader('X-Refresh-Token'));
              resolve();
            });
            posting.fail(() => {
              SpaceIO.expireSession("expiry of refresh token");
              reject();
            });
          });
      } catch (err) {
        throw err;
      }
    },
    authGetJSON: async function (url, data) {
      return await this.authGet(url, data, 'json');
    },
    authGetShiftManagementJSON: async function (url, data) {
      return await this.authGetShiftManagementData(url, data, 'json');
    },
    authPostJSON: async function (url, data, useBase) {
      return await this.authPost(useBase ? this.base + url : this.base + "/api/" + url, data, 'json');
    },
    authPostShiftManagementJSON: async function (url, data) {
      return await this.authPostShiftManagementData(url, data, 'json');
    },
    authGetText: async function (url, data) {
      return await this.authGet(url, data, 'text');
    },
    async authGetShiftManagementData(url, data, dataType) {
      return defferedAjax({
        url: configService.shiftManagementApi + url,
        dataType: dataType,
        data: data,
        headers: { "Authorization": `Bearer ${await AuthTokenService.getKey()}` },
        cache: false,
        statusCode: {
          401: function () {
            SpaceIO.expireSession("invalid access token");
          }
        }
      });
    },
    async authPostShiftManagementData(url, data, dataType) {
      return defferedAjax({
        url: configService.shiftManagementApi + url,
        type: 'POST',
        contentType: 'application/json',
        dataType: dataType,
        data: JSON.stringify(data),
        headers: { "Authorization": `Bearer ${await AuthTokenService.getKey()}` },
        cache: false,
        statusCode: {
          401: function () {
            SpaceIO.expireSession("invalid access token");
          }
        }
      });
    },
    async authPutShiftManagementData(url, data, dataType) {
      return $.ajax({
        url: configService.shiftManagementApi + url,
        type: 'PUT',
        contentType: 'application/json',
        dataType: dataType,
        data: JSON.stringify(data),
        headers: { "Authorization": `Bearer ${await AuthTokenService.getKey()}` },
        cache: false,
      });
    },
    async authDeleteShiftManagementData(url) {
      return $.ajax({
        url: configService.shiftManagementApi + url,
        type: 'DELETE',
        headers: { "Authorization": `Bearer ${await AuthTokenService.getKey()}` },
        cache: false,
      });
    },
    async authGet(url, data, dataType, useBase) {
      return defferedAjax({
        url: useBase ? this.base + url : this.base + "/api/" + url,
        dataType: dataType,
        data: data,
        headers: { "X-Auth-Key": await AuthTokenService.getKey() },
        cache: false,
        statusCode: {
          401: function () {
            SpaceIO.expireSession("invalid access token");
          }
        }
      });
    },
    async authGetFreespaceapp(query) {
      return defferedAjax({
        url: configService.userAppUrl,
        type: 'POST',
        contentType: 'application/json',
        data: JSON.stringify({
          query: query
        }),
        headers: { "Authorization": `Bearer ${await AuthTokenService.getKey()}` },
        cache: true,

      });
    },
    async authGetFreespaceappNoGraphQL(url) {
      return defferedAjax({
        url: configService.userAppUrl.replace('graphql', url),
        type: 'GET',
        headers: { "Authorization": `Bearer ${await AuthTokenService.getKey()}` },
      });
    },
    async authPostFreespaceapp(query, variables) {
      return defferedAjax({
        url: configService.userAppUrl,
        type: 'POST',
        contentType: 'application/json',
        data: JSON.stringify({
          query: query,
          variables: variables
        }),
        headers: { "Authorization": `Bearer ${await AuthTokenService.getKey()}` },
        cache: true,

      });
    },
    async authGetPositionJSON(url, dataType) {
      return defferedAjax({
        url: this.positionBase + url,
        dataType: dataType,
        headers: { "X-Auth-Key": await AuthTokenService.getKey() },
        cache: false,
      });
    },
    authPostPositionJSON: async function (url, data) {
      return await this.authPost(configService.positionBaseUrl + url, data, 'json', true);
    },
    authPostFireLoadingJSON: async function (url, data) {
      return await this.authPost(configService.fireLoadingUrl + "/api" + url, data, 'json', true);
    },
    async authGetFireLoadingJSON(url, data) {
      return defferedAjax({
        url: configService.fireLoadingUrl + url,
        data: data,
        dataType: 'json',
        baseUrl: this.base,
        headers: { "X-Auth-Key": await AuthTokenService.getKey() },
        statusCode: {
          401: function () {
            SpaceIO.expireSession("invalid access token");
          }
        }
      });
    },
    get(url) {
      return defferedAjax(url);
    },
    async authPost(url, data, dataType) {
      return defferedAjax({
        url: url,
        type: 'POST',
        contentType: 'application/json',
        dataType: dataType,
        data: JSON.stringify(data),
        headers: { "X-Auth-Key": await AuthTokenService.getKey() },
        cache: false,
        statusCode: {
          401: function () {
            SpaceIO.expireSession("invalid access token");
          }
        }
      });
    },
    async authPut(url, data, dataType) {
      return $.ajax({
        url: this.base + "/api/" + url,
        type: 'PUT',
        contentType: 'application/json',
        dataType: dataType,
        data: JSON.stringify(data),
        headers: { "X-Auth-Key": await AuthTokenService.getKey() },
        cache: false,

      });
    },
    async authGetMethod(url) {
      return $.ajax({
        url: this.base + "/api/" + url,

        headers: { "X-Auth-Key": await AuthTokenService.getKey() }

      });
    },
    async authDelete(url) {
      return $.ajax({
        url: this.base + "/api/" + url,
        type: 'DELETE',
        headers: { "X-Auth-Key": await AuthTokenService.getKey() },
        cache: false,
      });
    }
  },
  expireSession: function (expireDueTo) {
    this.sessionExpiryCallback(expireDueTo);
  },
  logout: function () {
    let currenturl = window.location.href;
    let hosturl = window.location.href;
    hosturl = currenturl.substr(0, currenturl.lastIndexOf("/")) + "/";
    window.localStorage.clear();
    window.location.href = `${configService.authURL}?referrer=${encodeURIComponent(hosturl)}`;

  },
  switchAccount: async function (accountId) {
    await SpaceIO.waitForInit();
    // Note using Spaceio auth get as this needs responseHeaders
    const posting = $.ajax({
      url: SpaceIO.api.base + "/api/" + 'switchto/account/' + accountId,
      dataType: 'json',
      headers: { "X-Auth-Key": await AuthTokenService.getKey() },
      cache: false,
      statusCode: {
        401: function () {
          SpaceIO.expireSession("invalid access token");
        }
      }
    });
    posting.done((user) => {
      SpaceIO.user = user;
      AuthTokenService.setKey(posting.getResponseHeader('X-Auth-Key'));
      RefreshTokenService.setKey(posting.getResponseHeader('X-Refresh-Token'));
      SpaceIO.idleTimeoutStatus = posting.getResponseHeader("X-Idle-Timeout-Status");
      window.location.reload(false);
    });
    posting.fail(function () {
      this.expireSession("invalid access token");
    });
  },
  showHideUserPref: function () {
    let descriptionDetails = window.localStorage.getItem("freespace-user-pref");
    let objDetails = $.parseJSON(descriptionDetails);
    let upIds = $('.user-pref-description').map(function (e) {
      return $(e).attr('id');
    });
    _.each(objDetails, function (item) {
      if (item.pageDescription != null) {
        let descriptionName = item.pageDescription.name;
        let flag = item.hideFlag;
        if (flag)
          upIds = upIds.filter(function (id) {
            return ('user-preference_' + descriptionName) != id;
          });
      }
    });
    _.each(upIds, function (item) {
      $('#' + item).removeClass('hide');
    });


    $(".user-pref-description .close").click(async function () {
      let nameStr = $(this).parent('div').attr('id').split('_', 2)[1];
      let posting = $.ajax({
        type: "POST",
        url: SpaceIO.api.base + '/api/admin/pageDescUserPrefs',
        data: JSON.stringify({
          pageDescription: {
            name: nameStr
          },
          hideFlag: true
        }),
        contentType: "application/json",
        headers: {
          "X-Auth-Key": await AuthTokenService.getKey()
        }
      });

      // Put the results in a div
      posting.done(function () {
        $.when(SpaceIO.api.authGetJSON('admin/pageDescUserPrefs')).done(function (pageDescUserPrefs) {
          window.localStorage.setItem("freespace-user-pref", JSON.stringify(pageDescUserPrefs));

        });
      });
    });

  },
  getParameterByName: function (name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[[\]]/g, '\\$&');
    let regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  },
  removeParameterByName: function (name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[[\]]/g, '\\$&');
    let regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
    if (results && results[0]) {
      url = url.replace(results[0], results[0][0] == '?' ? "?" : "");
    }
    return url;
  },
  get lastAction() {
    const lastAction = window.localStorage.getItem('spaceio-lastAction');
    return lastAction ? Number(lastAction) : null;
  },
  set lastAction(time) {
    window.localStorage.setItem('spaceio-lastAction', time);
  },
  get user() {
    let user = this.__user;
    if (user) {
      return user;
    }
    user = JSON.parse(window.localStorage.getItem('spaceio-user'));
    this.user = user;
    return user;
  },
  set user(user) {
    this.__user = user;
    if (user) {
      window.localStorage.setItem('spaceio-user', JSON.stringify(user));
    } else {
      window.localStorage.removeItem('spaceio-user');
    }
  },
  get idleTimeoutStatus() {
    let idleTimeoutStatus = this.__idleTimeoutStatus;
    if (idleTimeoutStatus) return idleTimeoutStatus;
    idleTimeoutStatus = window.localStorage.getItem('spaceio-idle-timeout-status');
    this.__idleTimeoutStatus = idleTimeoutStatus;
    return idleTimeoutStatus;
  },
  set idleTimeoutStatus(status) {
    this.__idleTimeoutStatus = status;
    if (status) window.localStorage.setItem("spaceio-idle-timeout-status", status);
    else window.localStorage.removeItem("spaceio-idle-timeout-status");
  },
  get lastSuccessFullApiCall() {
    return this.__lastSuccessFullApiCall;
  },
  set lastSuccessFullApiCall(lastSuccessFullApiCallStatus) {
    this.__lastSuccessFullApiCall = lastSuccessFullApiCallStatus;
  },
  removeSensitiveParams() {
    if (!window.location.search) return;
    const sensitiveParams = ['xauthcode', 'xdomainname'];
    let search = window.location.search;
    while (sensitiveParams.length) {
      search = SpaceIO.removeParameterByName(sensitiveParams.pop(), search);
    }
    window.history.replaceState(null, null, window.location.pathname + search + window.location.hash);
  },
  async __init() {

    // await new Promise((r) => setTimeout(r, 10000));
    try {
      console.log('spaceio init start');
      let domain = "";
      if (this.user) domain = this.user.username.split("@")[1];
      else domain = SpaceIO.getParameterByName("xdomainname");
      if (domain) {
        const discovery = await MultiRegionService.getAccountDataCDN(domain);
        if (discovery && discovery.ACCOUNT_UI) {
          SpaceIO.api.base = discovery.ACCOUNT_UI.baseUrl;
          SpaceIO.api.positionBase = discovery.ACCOUNT_UI.positionBaseUrl;
          Object.keys(discovery.ACCOUNT_UI).map(discoveryKey => {
            if (configService[discoveryKey])
              configService[discoveryKey] = discovery.ACCOUNT_UI[discoveryKey];
          });
        }
      }
      if (!await AuthTokenService.getKey()) {

        let code = SpaceIO.getParameterByName('xauthcode');
        this.lastAction = new Date().getTime();
        if (code) {
          await new Promise((resolve, reject) => {
            const posting = $.ajax({
              url: configService.baseUrl + '/login/code',
              type: 'POST',
              headers: { 'X-Auth-Code': code }
            });
            posting.done((user) => {
              this.user = user;
              SpaceIO.lastSuccessFullApiCall = true;
              AuthTokenService.setKey(posting.getResponseHeader('X-Auth-Key'));
              if (posting.getResponseHeader('X-Refresh-Token'))
                RefreshTokenService.setKey(posting.getResponseHeader('X-Refresh-Token'));
              SpaceIO.idleTimeoutStatus = posting.getResponseHeader("X-Idle-Timeout-Status");
              SpaceIO.lastSuccessFullApiCall = true;
              // setTimeout(resolve, 5000);
              resolve();
            });
            posting.fail(() => {
              this.expireSession("invalid auth code");
              reject();
            });
          });
        } else {
          this.expireSession("invalid access token");
        }
      }
      CacheService.validateNewRelease();
      if (!this.user) {
        if (!await AuthTokenService.getKey() || !SpaceIO.lastSuccessFullApiCall) SpaceIO.logout();
        else this.user = await this.api.authGetJSON('self');
      }
      this.removeSensitiveParams();
      console.log('spaceio init done');
    } catch (err) {
      console.error('error initialising', err);
      throw err;
    }

  },
  onSessionExpiry() {
    return this.sessionExpiryPromise;
  }
};
SpaceIO.sessionExpiryPromise = new Promise(resolve => SpaceIO.sessionExpiryCallback = resolve);

BaseService.decorate(SpaceIO, MultiRegionService);
export default SpaceIO;
