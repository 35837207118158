import BaseService from './base-service';
import TokenService from "./token-service";

const RefreshTokenService = {
  getKey() {
    let refreshToken = this.__key;
    if (refreshToken) return refreshToken;
    refreshToken = window.localStorage.getItem('spaceio-refresh-token');
    if (refreshToken) {
      this.key = refreshToken;
      return refreshToken;
    } else return null;
  },
  setKey(key) {
    this.__key = key;
    if (key) window.localStorage.setItem('spaceio-refresh-token', key);
    else window.localStorage.removeItem('spaceio-refresh-token');
  },
  isExpired() {
    if (!this.__key)
      return true;
    return TokenService.isExpired(this.key);
  },
  getClaim() {
    return TokenService.getClaim(this.key);
  },
  getIdleTimeOut() {
    const claim = TokenService.getClaim(this.key);
    return claim['itt'] || null;
  },
};

BaseService.decorate(RefreshTokenService, TokenService);
export default RefreshTokenService;
