import React from 'react';
import Loader from './loader';

function Container(props) {
  return (<div className="box box-solid" style={{ padding: '12px', minHeight: '100px' }} hidden={props.hidden}>
    {props.children}
  </div>);
}


function Hidable(props) {

  return (<React.Fragment>
    <div hidden={props.hidden} style={props.style}>
      {props.children}
    </div>
    {
      props.showLoader && props.hidden ?
        <div style={props.style}>
          <Loader />
        </div> : ''
    }
  </React.Fragment>);
}

function WideRow(props) {
  return (<div className="row">
    <div className="col-sm-12">
      {props.children}
    </div>
  </div>);
}


export {
  Container,
  WideRow,
  Hidable
};
