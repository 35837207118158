import * as React from "react";

const SvgTablet = (props) => (
  <svg
    width={18}
    height={18}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      className="stroke"
      d="M7.5 6.75h-3M11.625 8.25a1.875 1.875 0 1 1 0-3.75 1.875 1.875 0 0 1 0 3.75ZM4.5 4.5h2.25M13.5 13.5l-3.375-2.25-1.875 1.5-3.75-3"
      stroke="#fff"
      strokeOpacity={0.6}
      strokeWidth={1.125}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      className="stroke"
      d="M2.25 15.3V2.7a.45.45 0 0 1 .45-.45h12.6a.45.45 0 0 1 .45.45v12.6a.45.45 0 0 1-.45.45H2.7a.45.45 0 0 1-.45-.45Z"
      stroke="#fff"
      strokeOpacity={0.6}
      strokeWidth={1.125}
    />
  </svg>
);

export default SvgTablet;
